export const invoiceThemes = [{
    themeGroupTitle: 'Classic Themes',
    themeData: [
        {
            id : "TallyTheme",
            title: 'Tally Theme',
            details: 'A simple, professional theme with clean lines and clear typography.',
            meta: {
                primaryColor: '#4CAF50',
                secondaryColor: '#F5F5F5',
                textColor: '#000000',
                primaryTextColor: '#1A1A1A',
                secondaryTextColor: '#666666',
                backgroundColor: '#FFFFFF',
                fontSize: '14px',
                fontFamily: 'Arial, sans-serif',
            },
        },
        {
            id : "CorporateBlue",
            title: 'Corporate Blue',
            details: 'A corporate theme with a blue color palette for professional invoices.',
            meta: {
                primaryColor: '#1E88E5',
                secondaryColor: '#E3F2FD',
                textColor: '#212121',
                primaryTextColor: '#0D47A1',
                secondaryTextColor: '#757575',
                backgroundColor: '#FFFFFF',
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif',
            },
        },
    ],
},
{
    themeGroupTitle: 'Vintage Themes',
    themeData: [
        {
            id : "VintageClassic",
            title: 'Vintage Classic',
            details: 'A theme with muted colors and serif fonts for a timeless look.',
            meta: {
                primaryColor: '#8B4513',
                secondaryColor: '#FBE9E7',
                textColor: '#5D4037',
                primaryTextColor: '#3E2723',
                secondaryTextColor: '#795548',
                backgroundColor: '#FFF8E1',
                fontSize: '15px',
                fontFamily: 'Georgia, serif',
            },
        },
        {
            id : "ReleCloudTheme",
            title: 'Rele Cloud',
            details: 'A theme with muted colors and serif fonts for a timeless look.',
            meta: {
                primaryColor: '#8B4513',
                secondaryColor: '#FBE9E7',
                textColor: '#5D4037',
                primaryTextColor: '#3E2723',
                secondaryTextColor: '#795548',
                backgroundColor: '#FFF8E1',
                primaryFontSize: '15px',
                secondaryFontSize : '12px',
                tertiaryFontSize : '9px',
                fontFamily: 'Georgia, serif',
            },
        },
        {
            id : "ElegantBlissTheme",
            title: 'Elegant Bliss',
            details: 'A theme with muted colors and serif fonts for a timeless look.',
            meta: {
                primaryColor: '#8B4513',
                secondaryColor: '#FBE9E7',
                textColor: '#5D4037',
                primaryTextColor: '#3E2723',
                secondaryTextColor: '#795548',
                backgroundColor: '#E2F5FA',
                primaryFontSize: '15px',
                secondaryFontSize : '12px',
                tertiaryFontSize : '9px',
                fontFamily: 'Montserrat',
            },
        },
        {
            id : "RusticCharmTheme",
            title: 'Rustic Charm',
            details: 'A warm and welcoming theme with earthy tones.',
            meta: {
                primaryColor: '#A0522D',
                secondaryColor: '#FFF3E0',
                textColor: '#4E342E',
                primaryTextColor: '#3E2723',
                secondaryTextColor: '#6D4C41',
                backgroundColor: '#FFECB3',
                fontSize: '16px',
                fontFamily: 'Palatino, serif',
            },
        },
    ],
},
{
    themeGroupTitle: 'Modern Themes',
    themeData: [
        {
            id : "HappiestValleyTheme",
            title: 'Happiest Valley Theme',
            details: 'A sleek and minimalist design with ample white space.',
            meta: {
                primaryColor: '#F5F5F5',
                secondaryColor: '#E0E0E0',
                textColor: '#212121',
                primaryTextColor: '#424242',
                secondaryTextColor: '#757575',
                backgroundColor: '#FFFFFF',
                fontSize: '13px',
                fontFamily: 'Helvetica, sans-serif',
            },
        },
        {
            id : "SleekInvoiceTheme",
            title: 'Sleek Invoice Theme',
            details: 'A sleek and minimalist design with ample white space.',
            meta: {
                primaryColor: '#F5F5F5',
                secondaryColor: '#E0E0E0',
                textColor: '#212121',
                primaryTextColor: '#424242',
                secondaryTextColor: '#757575',
                backgroundColor: '#FFFFFF',
                fontSize: '13px',
                fontFamily: 'Helvetica, sans-serif',
            },
        },
        {
            title: 'Minimalist White',
            details: 'A sleek and minimalist design with ample white space.',
            meta: {
                primaryColor: '#F5F5F5',
                secondaryColor: '#E0E0E0',
                textColor: '#212121',
                primaryTextColor: '#424242',
                secondaryTextColor: '#757575',
                backgroundColor: '#FFFFFF',
                fontSize: '13px',
                fontFamily: 'Helvetica, sans-serif',
            },
        },
        {
            title: 'Bold and Bright',
            details: 'A modern theme with bold typography and bright accents.',
            meta: {
                primaryColor: '#FF5722',
                secondaryColor: '#FFCCBC',
                textColor: '#212121',
                primaryTextColor: '#BF360C',
                secondaryTextColor: '#FF6F00',
                backgroundColor: '#FAFAFA',
                fontSize: '14px',
                fontFamily: 'Montserrat, sans-serif',
            },
        },
    ],
},
{
    themeGroupTitle: 'Creative Themes',
    themeData: [
        {
            title: 'Artistic Flair',
            details: 'A creative design with decorative borders and unique fonts.',
            meta: {
                primaryColor: '#673AB7',
                secondaryColor: '#D1C4E9',
                textColor: '#311B92',
                primaryTextColor: '#4527A0',
                secondaryTextColor: '#9575CD',
                backgroundColor: '#EDE7F6',
                fontSize: '15px',
                fontFamily: '"Comic Sans MS", sans-serif',
            },
        },
        {
            title: 'Gradient Glow',
            details: 'A vibrant theme with gradient backgrounds and stylish typography.',
            meta: {
                primaryColor: '#FF6F00',
                secondaryColor: '#FFD54F',
                textColor: '#212121',
                primaryTextColor: '#E65100',
                secondaryTextColor: '#FFA726',
                backgroundColor: 'linear-gradient(to right, #FF6F00, #FFD54F)',
                fontSize: '14px',
                fontFamily: 'Poppins, sans-serif',
            },
        },
    ],
},
]