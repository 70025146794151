import React, { useEffect, useState } from "react";
import { urlToBase64 } from "../../../Helper/utility";
import styles from "./HappiestValleyTheme.module.css";

const HappiestValleyTheme = ({ selectedPalette, invoiceDataMaster, themeData }) => {
    const { meta } = themeData;
    const {
        clientData,
        productData,
        totalAmount,
        tncData,
        customFields,
        invoiceDate,
        dueDate,
    } = invoiceDataMaster;

    const [base64Logo, setBase64Logo] = useState(null);
    const fetchBase64Logo = async () => {
        try {
            const base64 = await urlToBase64(
                "https://img.freepik.com/premium-vector/hair-salon-logo-design-crown-salon_290562-205.jpg"
            );
            setBase64Logo(base64);
        } catch (error) {
            console.error("Error converting image to base64:", error);
        }
    };

    useEffect(() => {
        fetchBase64Logo();
    }, []);

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: selectedPalette.backgroundColor ?? meta.backgroundColor,
                color: selectedPalette.textColor ?? meta.textColor,
                fontFamily: meta.fontFamily,
            }}
            id="invoicePreview"
        >
            {/* Header */}
            <header
                className={styles.header}
                style={{
                    backgroundColor: selectedPalette.tableHeaderColor ?? meta.tableHeaderColor,
                    color: selectedPalette.tableHeaderTextColor ?? meta.tableHeaderTextColor,
                }}
            >
                <div className={styles.logoSection}>
                    {base64Logo ? (
                        <img
                            src={base64Logo}
                            alt="Salon Logo"
                            className={styles.logo}
                            id="logo"
                        />
                    ) : (
                        <p>Loading logo...</p>
                    )}
                    <h1 style={{ color: selectedPalette.primaryButtonColor ?? meta.primaryColor }}>
                        Happiest Valley Farms
                    </h1>
                    <p>Happy plants mean happy people</p>
                </div>
                <div className={styles.invoiceSection}>
                    <h2
                        style={{
                            color: selectedPalette.primaryButtonTextColor ?? meta.primaryTextColor,
                        }}
                    >
                        INVOICE
                    </h2>
                    <p>Invoice #: {invoiceDataMaster.sessionIdentifier.slice(-6)}</p>
                    <p>Invoice Date: {invoiceDate}</p>
                    <p>Due Date: {dueDate}</p>
                </div>
            </header>

            {/* Client Information */}
            <section className={styles.clientInfo}>
                <h3
                    style={{
                        color: selectedPalette.secondaryButtonTextColor ?? meta.secondaryTextColor,
                    }}
                >
                    To:
                </h3>
                <p>{clientData.client_name}</p>
                <p>{clientData.client_address}</p>
                <p>Phone: {clientData.mobile}</p>
                <p>Email: {clientData.client_email}</p>
            </section>

            {/* Product Table */}
            <div className={styles.productWrapper}>
                <table className={styles.productTable}>
                    <thead
                        style={{
                            backgroundColor: selectedPalette.tableHeaderColor,
                            color: selectedPalette.tableHeaderTextColor,
                        }}
                    >
                        <tr>
                            <th>Qty</th>
                            <th>Description</th>
                            <th>Unit Price</th>
                            <th>Line Total</th>
                        </tr>
                    </thead>
                    <tbody
                        style={{
                            backgroundColor: selectedPalette.tableBodyBackgroundColor,
                            color: selectedPalette.tableBodyTextColor,
                            borderColor: selectedPalette.tableBodyBorderColor,
                        }}
                    >
                        {productData.map((product, index) => (
                            <tr key={index}>
                                <td>{product.qty}</td>
                                <td>{product.productTitle}</td>
                                <td>${product.price}</td>
                                <td>${product.total}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Summary */}
            <section className={styles.summary}>
                <div>
                    <p>Subtotal:</p>
                    <p>Sales Tax:</p>
                    <p style={{ fontWeight: "bold" }}>Total:</p>
                </div>
                <div>
                    <p>${totalAmount}</p>
                    <p>18%</p>
                    <p style={{ fontWeight: "bold" }}>${totalAmount}</p>
                </div>
            </section>

            {/* Terms & Conditions */}
            <footer className={styles.footer}>
                <p
                    style={{
                        fontSize: meta.tertiaryFontSize,
                        color: selectedPalette.invoiceNotesColor ?? meta.tertiaryTextColor,
                    }}
                >
                    Make all checks payable to Happiest Valley Farms. Thank you for your business!
                </p>
                <p>{customFields[0]?.value}</p>
                <address>{tncData[0]?.detail}</address>
                <hr
                    style={{
                        backgroundColor: selectedPalette.sectionDividingLineColor,
                        height: "1px",
                        border: "none",
                    }}
                />
            </footer>
        </div>
    );
};

export default HappiestValleyTheme;
