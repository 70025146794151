import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MdClose } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import styles from './ConfirmationPopup.module.css'

const ConfirmationPopup = ({ isOpen, onClose, onDelete, onMarkComplete, isProcessing, triggeredBookingTab }) => {
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === "Escape") onClose();
        };
        window.addEventListener("keydown", handleEscape);
        return () => window.removeEventListener("keydown", handleEscape);
    }, [onClose]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className={styles.overlay}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                >
                    <motion.div
                        className={styles.popup}
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -50, opacity: 0 }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button className={styles.closeButton} onClick={onClose}>
                            <MdClose size={20} />
                        </button>
                        <h2>Confirm Action</h2>
                        <p>What would you like to do with this booking?</p>
                        <div className={styles.actions}>
                            <button
                                className={styles.deleteButton}
                                onClick={() => onDelete(triggeredBookingTab)}
                                disabled={isProcessing}
                            >
                                {isProcessing ? <FaSpinner className={styles.spinner} /> : "Delete Booking"}
                            </button>
                            <button
                                className={styles.completeButton}
                                onClick={() => onMarkComplete(triggeredBookingTab)}
                                disabled={isProcessing}
                            >
                                {isProcessing ? <FaSpinner className={styles.spinner} /> : "Mark As Completed"}
                            </button>
                        </div>
                        <button className={styles.cancelButton} onClick={onClose} disabled={isProcessing}>
                            Cancel
                        </button>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ConfirmationPopup;
