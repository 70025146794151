import React, { useContext, useEffect, useState } from 'react';
import styles from './ViewInvoice.module.css'
import { useParams, useNavigate } from 'react-router-dom';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf';
import { IoMdCloudDownload } from 'react-icons/io';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import { capitalizeFirstLetter, convertDateToDdMmYyyy, convertToAmPm, dateDifference, getAge, getAgeFull, numberToWords, roundOff } from '../../Helper/calculations';
import InvoiceBrandLogo from './InvoiceBrandLogo'
import { BiArrowBack } from 'react-icons/bi';
import { FaPhoneAlt, FaToggleOff, FaToggleOn } from 'react-icons/fa';
import { isEqual } from 'lodash';
import { MdDelete, MdEmail, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import { MdLocationPin } from "react-icons/md";
// import html2pdf from 'html2pdf.js';




const ViewInvoice = () => {
    const { invoiceIdentifier } = useParams(); // Get the invoiceIdentifier from URL
    const nav = useNavigate()
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [vendorStoreRefers, setVendorStoreRefers] = useState([])
    const [newRefers, setNewRefers] = useState('')
    const [isAddingNewRefers, setIsAddingNewRefers] = useState(false)
    const [isDeletingRefers, setIsDeletingRefers] = useState(false)

    window.onclick = event => event.target.id === "vimctopid" ? nav(`/invoices`) : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            nav(`/invoices`)
        }
    });

    const { toPDF, targetRef } = usePDF({ filename: `${invoiceIdentifier}.pdf` });
    const [hasEdited, setHasEdited] = useState(false)


    const options = {
        resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            // format: 'letter',
            // default is 'portrait'
            // orientation: 'landscape',
        }

    };

    const [invoiceData, setInvoiceData] = useState(null)
    const [invoiceDataToUpdate, setInvoiceDataToUpdate] = useState(null)
    const [isGstBill, setIsGstBill] = useState(false)
    const [brandGSTs, setBrandGSTs] = useState([
        {
            gstNumber: '29ACXPL2065P1ZL',

        }
    ])

    const [generateBillThroughOptions, setGenerateBillThroughOptions] = useState([
        {
            "storeIdentifier": "561726767687003789",
            "storeName": "RAJESHWARI AYURDHAMA",
            "storeAddress": "#607, Ravi Nenapu, 7th Main road, Havanoor Extn, Near Hesaraghatta Main Road, Bengaluru – 560073",
            "storeEmail": "rajeshwariayurdhama@gmail.com",
            "storePrimaryPhone": "8217368304",
            "storeSecondaryPhone": "8147042415",
            "storeTertiaryPhone": "",
            "invoiceLogo": "https://crm.utpalaayurdhama.com/rajeshwari-logo-web.png",
            "invoiceDefaultNotes": ""
        },
        {
            "storeIdentifier": "561727240952003789",
            "storeName": "UTPALA AYURDHAMA",
            "storeAddress": "New BEL Rd, Chikkamaranahalli, Dollars Colony, R.M.V. 2nd Stage, Bengaluru, Karnataka 560094",
            "storeEmail": "info@utpalaayurdhama.com",
            "storePrimaryPhone": "7259195959",
            "storeSecondaryPhone": "",
            "storeTertiaryPhone": "",
            "invoiceLogo": "https://crm.utpalaayurdhama.com/utpala-logo-web.png",
            "invoiceDefaultNotes": ""
        }
    ])

    const [defaultNotes, setDefaultNotes] = useState([
        {
            title: 'forMedicine',
            label: 'For Medicine',
            content: `<ul>
            <li>Ensure to verify the invoice before you leave.</li>
            <li>Prices charged are inclusive of taxes & duties paid.</li>
            <li>Goods once sold cannot be taken back or exchanged.</li>
            <li>We greatly appreciate your visit, You’re a valued customer at UTPALA AYURDHAMA, and we look forward to continuing future service with you.</li>
            <li>If you have any questions or concerns about these invoice, please contact or e-mail us.</li>
            <li>Thank you for your continued trust and support!</li>
            <li>To know more about our service please visit https://utpalaayurdhama.com</li>
            </ul>
            <p>Thank You! Visit us again…!</p>
            UTPALA AYURDHAMA (BRANCH OF RAJESHWARI AYURDHAMA).`
        },
        {
            title: 'forServices',
            label: 'For Services',
            content: `
            <ul>
            <li>Ensure to verify the invoice before you leave. </li>
            <li>If you have any questions or concerns about these invoice, please contact or E-mail us. </li>
            <li>Thank you for your continued trust and support! </li>
            <li>We greatly appreciate your visit, You’re a valued customer at UTPALA AYURDHAMA, and we look forward to continuing future service with you. </li>
            <li>To know more about our service please visit https://utpalaayurdhama.com </li>
            <p>Please visit us again…!</p>
            </ul>`
        }
    ])

    // console.log("this is auth ")
    // console.log(JSON.parse(authCtx?.loginData))

    const getStoreGST = async () => {
        const getStoreGSTResponse = await fetch(BASE_URL_API + "getStoreGST",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    vendorIdentifier: JSON.parse(authCtx?.loginData)?.partnersData?.vendorIdentifier
                })
            });

        if (!getStoreGSTResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getStoreGSTRespo = await getStoreGSTResponse.json()
            if (getStoreGSTRespo?.status === "success") {
                setBrandGSTs(getStoreGSTRespo?.response)
            } else {
                if (getStoreGSTRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setBrandGSTs([])
                }
            }
            console.log(getStoreGSTRespo)
        }

    }


    const getVendorStoreRefers = async () => {
        const getVendorStoreRefersResponse = await fetch(BASE_URL_API + "getVendorStoreRefers",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    vendorIdentifier: JSON.parse(authCtx?.loginData)?.partnersData?.vendorIdentifier,
                    storeIdentifier: activeStore?.storeIdentifier,
                })
            });

        if (!getVendorStoreRefersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getVendorStoreRefersRespo = await getVendorStoreRefersResponse.json()
            if (getVendorStoreRefersRespo.status === "success") {
                setVendorStoreRefers(getVendorStoreRefersRespo.response)
            } else {
                if (getVendorStoreRefersRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(getVendorStoreRefersRespo)
        }
    }

    const addVendorStoreRefers = async () => {
        setIsAddingNewRefers(true)
        const addVendorStoreRefersResponse = await fetch(BASE_URL_API + "addVendorStoreRefers",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: JSON.parse(authCtx?.loginData)?.partnersData?.vendorIdentifier,
                    storeIdentifier: activeStore?.storeIdentifier,
                    fullName: newRefers
                })
            });

        if (!addVendorStoreRefersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addVendorStoreRefersRespo = await addVendorStoreRefersResponse.json()
            if (addVendorStoreRefersRespo?.status === "success") {
                getVendorStoreRefers()
                setNewRefers('')
            } else {
                if (addVendorStoreRefersRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(addVendorStoreRefersRespo)
                }
            }

        }
        setIsAddingNewRefers(false)
    }

    const deleteVendorStoreRefers = async (k) => {
        setIsDeletingRefers(true)
        const deleteVendorStoreRefersResponse = await fetch(BASE_URL_API + "deleteVendorStoreRefers",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: JSON.parse(authCtx?.loginData)?.partnersData?.vendorIdentifier,
                    storeIdentifier: activeStore?.storeIdentifier,
                    ID: k
                })
            });

        if (!deleteVendorStoreRefersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteVendorStoreRefersRespo = await deleteVendorStoreRefersResponse.json()
            if (deleteVendorStoreRefersRespo?.status === "success") {
                getVendorStoreRefers()
            } else {
                if (deleteVendorStoreRefersRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(deleteVendorStoreRefersRespo)
                }
            }

        }
        setIsDeletingRefers(false)
    }

    const getTargetElement = () => document.getElementById('content-id');

    const downloadPdfHandler = () => {
        const contentElement = document.getElementById("content-id");

        // Temporarily expand the element to its full height
        const originalWidth = contentElement.style.width;
        const originalHeight = contentElement.style.height;

        // Set width and height to auto to capture all content
        contentElement.style.width = '8.27in'; // A4 width in inches
        contentElement.style.height = 'auto';

        // Generate the PDF with full content
        generatePDF(getTargetElement, options).then(() => {
            // Restore original dimensions
            contentElement.style.width = originalWidth;
            contentElement.style.height = originalHeight;
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    // const downloadPdfHandler = () => {
    //     const contentElement = document.getElementById("content-id");

    //     // PDF options specifying A4 page size
    //     const options = {
    //         margin: 0.25,
    //         filename: 'document.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2 }, // Adjust scale for higher resolution
    //         jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    //     };

    //     html2pdf()
    //         .from(contentElement)
    //         .set(options)
    //         .save()
    //         .catch(error => {
    //             console.error('Error generating PDF:', error);
    //         });
    // };


    const updatePdfHandler = async () => {
        const updateInvoiceResponse = await fetch(BASE_URL_API + "updateInvoice",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    invoiceIdentifier: invoiceIdentifier,
                    invoiceDataToUpdate: invoiceDataToUpdate
                })
            });

        if (!updateInvoiceResponse.ok) {
            console.log(`Something went wrong : Server Error`);
        } else {
            const updateInvoiceRespo = await updateInvoiceResponse.json()
            if (updateInvoiceRespo.status === "success") {
                toast('Invoice updated!')
                setHasEdited(false)
            } else {
                if (updateInvoiceRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(updateInvoiceRespo)
                }
                setHasEdited(true)
            }
        }
    }

    const getInvoiceDetails = async () => {
        const getInvoiceDetailsResponse = await fetch(BASE_URL_API + "getInvoiceDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    invoiceKey: invoiceIdentifier
                })
            });

        if (!getInvoiceDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getInvoiceDetailsRespo = await getInvoiceDetailsResponse.json()
            if (getInvoiceDetailsRespo.status === "success") {
                setInvoiceData(getInvoiceDetailsRespo.response)
                setInvoiceDataToUpdate(getInvoiceDetailsRespo.response)
            } else {
                if (getInvoiceDetailsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(getInvoiceDetailsRespo)
        }
    }

    useEffect(() => {
        getInvoiceDetails()
    }, [invoiceIdentifier])

    useEffect(() => {
        getVendorStoreRefers()
    }, [invoiceIdentifier])


    const sumQuantities = (items) => {
        return items?.reduce((sum, item) => sum + parseInt(item?.quantity, 10), 0);
    }

    useEffect(() => {
        if (isEqual(invoiceData, invoiceDataToUpdate)) {
            setHasEdited(false);
        } else {
            setHasEdited(true);
        }
    }, [invoiceDataToUpdate]);

    const getItemsTotalDiscount = (items) => {
        let totalDiscount = 0;

        items?.forEach(item => {
            const totalBasePrice = parseFloat(item?.totalBasePrice);
            const totalDiscPrice = parseFloat(item?.totalDiscPrice);
            const quantity = parseInt(item?.quantity);

            if (!isNaN(totalBasePrice) && !isNaN(totalDiscPrice) && !isNaN(quantity)) {
                const itemDiscount = (totalBasePrice - totalDiscPrice) * quantity;
                totalDiscount += itemDiscount;
            }
        });

        return totalDiscount;
    };

    const getItemsTotal = (items) => {
        let itemsTotal = 0;

        items?.forEach(item => {
            const totalBasePrice = parseFloat(item?.totalBasePrice);
            const quantity = parseInt(item?.quantity);

            if (!isNaN(totalBasePrice) && !isNaN(quantity)) {
                const itemTotal = totalBasePrice * quantity;
                itemsTotal += itemTotal;
            }
        });

        return itemsTotal;
    };

    const getItemsTotalAfterDiscount = (items) => {
        let itemsTotal = 0;

        items?.forEach(item => {
            const totalDiscPrice = parseFloat(item?.totalDiscPrice);
            const quantity = parseInt(item?.quantity);

            if (!isNaN(totalDiscPrice) && !isNaN(quantity)) {
                const itemTotal = totalDiscPrice * quantity;
                itemsTotal += itemTotal;
            }
        });

        return itemsTotal;
    };

    useEffect(() => {
        console.log('wait, I am getting store gst')
        getStoreGST()
    }, [])


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer} id="vimctopid">
                <div className={styles.editingWrapper}>
                    <div className={styles.editingHeader}>
                        <div className={styles.backIcon}
                            onClick={() => nav(`/invoices`)}
                        >
                            <BiArrowBack size={24} />
                        </div>
                        <div>
                            Edit Invoice
                        </div>
                    </div>

                    <div className={styles.paramWrapper}>
                        <div className={styles.eachParamWrapperGST}>
                            <div className={styles.epwHeader}>
                                <div className={`${styles.fieldTitle} ${styles.gbtTitle}`}>Generate Bill Through:</div>
                            </div>

                            <div className={styles.gstWrapper}>
                                {generateBillThroughOptions?.map((item, index) => (
                                    <div className={styles.eachGstWrapper}
                                        onClick={() => {
                                            setInvoiceDataToUpdate((prevDetails) => ({
                                                ...prevDetails,
                                                gbtIdentifier: item?.storeIdentifier,
                                                gbtName: item?.storeName,
                                                gbtAddress: item?.storeAddress,
                                                gbtUrl: item?.invoiceLogo
                                            }));
                                        }}
                                    >
                                        <div className={styles.egwIcon}>
                                            {invoiceDataToUpdate?.gbtIdentifier === item?.storeIdentifier ?
                                                <MdRadioButtonChecked />
                                                :
                                                <MdRadioButtonUnchecked />
                                            }
                                        </div>
                                        <div className={styles.egwData}>
                                            <div>
                                                {item?.storeName}
                                            </div>
                                            <div className={styles.egwDataStoreAddress}>
                                                {item?.storeAddress}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={styles.eachParamWrapperGST}>
                            <div className={styles.epwHeader}>
                                <div className={styles.fieldTitle}>Make GST Bill</div>
                                <div
                                    className={styles.toggleIcon}
                                    onClick={() => {
                                        setInvoiceDataToUpdate((prevDetails) => ({
                                            ...prevDetails,
                                            isGstBill: invoiceDataToUpdate?.isGstBill === "1" ? '0' : '1'
                                        }));
                                    }}
                                >
                                    {invoiceDataToUpdate?.isGstBill === "1" ?
                                        <FaToggleOn size={30} color="#07Adff" />
                                        :
                                        <FaToggleOff size={30} color="#818181" />
                                    }
                                </div>
                            </div>
                        </div>
                        {invoiceDataToUpdate?.isGstBill === "1" &&
                            <div className={styles.eachParamWrapperGST}>
                                <div className={styles.epwHeader}>
                                    <div className={styles.fieldTitle}>Show Brand GST Details</div>
                                    <div
                                        className={styles.toggleIcon}
                                        onClick={() => {
                                            setInvoiceDataToUpdate((prevDetails) => ({
                                                ...prevDetails,
                                                showBrandGst: invoiceDataToUpdate?.showBrandGst === "1" ? '0' : '1'
                                            }));
                                        }}
                                    >
                                        {invoiceDataToUpdate?.showBrandGst === "1" ?
                                            <FaToggleOn size={30} color="#07Adff" />
                                            :
                                            <FaToggleOff size={30} color="#818181" />
                                        }
                                    </div>
                                </div>
                                {invoiceDataToUpdate?.showBrandGst === "1" &&
                                    <div className={styles.gstWrapper}>
                                        {brandGSTs?.map((item, index) => (
                                            <div className={styles.eachGstWrapper}
                                                onClick={() => {
                                                    setInvoiceDataToUpdate((prevDetails) => ({
                                                        ...prevDetails,
                                                        gstNumber: item?.gstNumber
                                                    }));
                                                }}
                                            >
                                                <div className={styles.egwIcon}>
                                                    {invoiceDataToUpdate?.gstNumber === item?.gstNumber ?
                                                        <MdRadioButtonChecked />
                                                        :
                                                        <MdRadioButtonUnchecked />
                                                    }
                                                </div>
                                                <div className={styles.egwData}>
                                                    <div>
                                                        {item?.tradeName}
                                                    </div>
                                                    <div>
                                                        {item?.gstNumber}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        }
                        {invoiceDataToUpdate?.isGstBill === "1" &&
                            <div className={styles.eachParamWrapper}>
                                <div className={styles.fieldTitle}>Show GST Table</div>
                                <div
                                    className={styles.toggleIcon}
                                    onClick={() => {
                                        setInvoiceDataToUpdate((prevDetails) => ({
                                            ...prevDetails,
                                            showTaxTableInInvoice: invoiceDataToUpdate?.showTaxTableInInvoice === "1" ? '0' : '1'
                                        }));
                                    }}
                                >
                                    {invoiceDataToUpdate?.showTaxTableInInvoice === "1" ?
                                        <FaToggleOn size={30} color="#07Adff" />
                                        :
                                        <FaToggleOff size={30} color="#818181" />
                                    }
                                </div>
                            </div>
                        }
                        <div className={styles.eachParamWrapperGST}>
                            <div className={styles.epwHeader}>
                                <div className={styles.fieldTitle}>Referred By:</div>
                                <div
                                    className={styles.toggleIcon}
                                    onClick={() => {
                                        setInvoiceDataToUpdate((prevDetails) => ({
                                            ...prevDetails,
                                            showReferredBy: invoiceDataToUpdate?.showReferredBy === "1" ? '0' : '1'
                                        }));
                                    }}
                                >
                                    {invoiceDataToUpdate?.showReferredBy === "1" ?
                                        <FaToggleOn size={30} color="#07Adff" />
                                        :
                                        <FaToggleOff size={30} color="#818181" />
                                    }
                                </div>
                            </div>

                            {invoiceDataToUpdate?.showReferredBy === "1" &&
                                <div className={styles.gstWrapper}>
                                    <div className={styles.allRefersWrapper}>
                                        {vendorStoreRefers?.map((item, index) => (
                                            <div className={styles.eachRefersWrapper}
                                                onClick={() => {
                                                    setInvoiceDataToUpdate((prevDetails) => ({
                                                        ...prevDetails,
                                                        referredByName: item?.fullName
                                                    }));
                                                }}
                                            >
                                                <div className={styles.erIcon}>
                                                    {invoiceDataToUpdate?.referredByName === item?.fullName ?
                                                        <MdRadioButtonChecked />
                                                        :
                                                        <MdRadioButtonUnchecked />
                                                    }
                                                </div>
                                                <div className={styles.erData}>
                                                    <div>
                                                        {item?.fullName}
                                                    </div>
                                                    <div>
                                                        {item?.phoneNumber}
                                                    </div>
                                                </div>
                                                <div className={styles.erAction}>
                                                    <button onClick={() => deleteVendorStoreRefers(item?.ID)} ><MdDelete size={20} color="red" /></button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className={styles.rbinWrapper} >
                                        <input className={styles.referredByNameInput}
                                            value={newRefers}
                                            placeholder="Enter Referred By name..."
                                            onChange={(e) => {
                                                setNewRefers(e.target.value);
                                            }}

                                        />
                                        {newRefers?.length > 0 &&
                                            <>
                                                {isAddingNewRefers === true ?
                                                    <button className={styles.addRefers}  >Adding...</button>
                                                    :
                                                    <button className={styles.addRefers} onClick={() => addVendorStoreRefers()} >Add</button>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={styles.tncTextEditable}>
                            <div className={styles.tncTitleEditable}>
                                <div>
                                    Notes:
                                </div>
                                <div className={styles.notesOption}>
                                    {defaultNotes?.map((item, index) => (
                                        <div key={index} onClick={() => {
                                            setInvoiceDataToUpdate((prevDetails) => ({
                                                ...prevDetails,
                                                invoiceTnc: item?.content
                                            }));
                                        }}
                                            className={styles.noTitle}>{item?.label}</div>
                                    ))}
                                </div>
                            </div>
                            <textarea
                                placeholder="Please mention terms and conditions..."
                                rows={8}
                                onChange={(e) => {
                                    const textareaValue = e.target.value;

                                    // Replace new lines (\n) with <br> tags for proper HTML rendering
                                    const htmlContent = textareaValue.replace(/\n/g, "<br>");

                                    setInvoiceDataToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        invoiceTnc: htmlContent // Store with <br> tags
                                    }));
                                }}
                                value={invoiceDataToUpdate?.invoiceTnc?.replace(/<br>/g, '\n')} // Convert <br> back to newlines for textarea display
                            >
                            </textarea>
                        </div>

                    </div>
                </div>
                <div className={`${styles.mainContent} ${styles.active}`}>
                    <div className={styles.mainHeader}>
                        Invoice Preview
                    </div>
                    <div className={styles.mainBody}>
                        <div className={styles.invoiceWrapper}>
                            <div className={styles.invoiceContainer}
                                ref={targetRef}
                                id="content-id"
                            >
                                <div className={styles.topWrapper}>
                                    <div className={styles.twFirstRow}>
                                        <div className={styles.businessWrapper}>
                                            <div>

                                                <InvoiceBrandLogo url={invoiceDataToUpdate?.gbtUrl} />
                                                <div className={styles.storeName}>
                                                    {invoiceDataToUpdate?.gbtName}
                                                </div>
                                                <div className={styles.storeAddress}>
                                                    {invoiceDataToUpdate?.gbtAddress}
                                                </div>
                                                {invoiceDataToUpdate?.isGstBill === "1" &&
                                                    invoiceDataToUpdate?.showBrandGst === "1" &&
                                                    <div className={styles.gstNumber}>
                                                        GSTIN : {invoiceDataToUpdate?.gstNumber}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.twSecondRow}>
                                        <div className={styles.clientWrapper}>
                                            <div>
                                                <div className={`${styles.clientName} ${styles.eachPdRow}`}>
                                                    <div className={styles.pdTitle}>
                                                        UHID 
                                                    </div>
                                                    <div className={styles.pdDivider}>:</div>
                                                    {invoiceDataToUpdate?.clientIdentifier?.substring(28)}
                                                </div>
                                                <div className={`${styles.clientName} ${styles.eachPdRow}`}>
                                                    <div className={styles.pdTitle}>
                                                        Name
                                                    </div>
                                                    <div className={styles.pdDivider}>:</div>
                                                    {invoiceDataToUpdate?.salutation} {invoiceDataToUpdate?.clientName}
                                                </div>

                                                <div className={`${styles.age} ${styles.eachPdRow}`}>
                                                    <div className={styles.pdTitle}>
                                                        Age / Gender
                                                    </div>
                                                    <div className={styles.pdDivider}>:</div>
                                                    {getAgeFull(invoiceDataToUpdate?.client_birthday)}
                                                    {invoiceDataToUpdate?.gender ? `, ${invoiceDataToUpdate.gender}` : ''}
                                                </div>

                                                <div className={`${styles.phoneNumber} ${styles.eachPdRow}`}>
                                                    <div className={styles.pdTitle}>
                                                        Phone
                                                    </div>
                                                    <div className={styles.pdDivider}>:</div>
                                                    +91-{invoiceDataToUpdate?.clientPhone}{invoiceDataToUpdate?.alternateNumber ? `, ${invoiceDataToUpdate.alternateNumber}` : ''}
                                                </div>
                                                <div className={`${styles.clientEmail} ${styles.eachPdRow}`}>
                                                    <div className={styles.pdTitle}>
                                                        E-mail
                                                    </div>
                                                    <div className={styles.pdDivider}>:</div>
                                                    {invoiceDataToUpdate?.client_email}
                                                </div>
                                                <div className={`${styles.clientAddress} ${styles.eachPdRow}`}>
                                                    <div className={styles.pdTitle}>
                                                        Address
                                                    </div>
                                                    <div className={styles.pdDivider}>:</div>
                                                    <div className={styles.pdValue}>
                                                        {invoiceDataToUpdate?.client_address}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {invoiceDataToUpdate?.showReferredBy === "1" &&
                                                    <div className={`${styles.referredByName} ${styles.eachPdRow}`}>
                                                        <div className={styles.pdTitle}>
                                                            Ref. By
                                                        </div>
                                                        <div className={styles.pdDivider}>:</div>
                                                        {invoiceDataToUpdate?.referredByName}
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                        <div className={styles.taxWrapper}>
                                            <div className={styles.taxTitle} >
                                                <div>
                                                    TAX INVOICE
                                                </div>
                                            </div>
                                            <div className={styles.taxDetails}>
                                                <div className={styles.eachTdRow}>
                                                    <div className={styles.etrTitle}>Bill No:</div>
                                                    <div className={styles.etrValue}>S/24-25/{invoiceDataToUpdate?.billCount}</div>
                                                </div>
                                                <div className={styles.eachTdRow}>
                                                    <div className={styles.etrTitle}>Date</div>
                                                    <div className={styles.etrValue}>{convertDateToDdMmYyyy(invoiceDataToUpdate?.createdOn?.substring(0, 10))}</div>
                                                </div>
                                                <div className={styles.eachTdRow}>
                                                    <div className={styles.etrTitle}>Time</div>
                                                    <div className={styles.etrValue}>{convertToAmPm(invoiceDataToUpdate?.createdOn?.substring(10, 20).trim())}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tableWrapper}>
                                    <div className={styles.tableHeader}>
                                        <div className={styles.sn}>#</div>
                                        <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.itemNameWithGst : styles.itemNameWithNoGst}>Item / Service Name</div>
                                        <div className={styles.itemCategory}>Category</div>
                                        {/* {invoiceDataToUpdate?.isGstBill === "1" && <div className={styles.batchNo}>Batch No.</div>} */}
                                        {invoiceDataToUpdate?.isGstBill === "1" && <div className={styles.hsn}>HSN</div>}
                                        {invoiceDataToUpdate?.isGstBill === "1" && <div className={styles.gst}>GST%</div>}
                                        <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.mrpWithGst : styles.mrpWithNoGst}>MRP</div>
                                        <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.qtyWithGst : styles.qtyWithNoGst}>Qty.</div>
                                        <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.rateWithGst : styles.rateWithNoGst}>Rate</div>
                                        <div className={styles.amount}>Amount</div>
                                        <div className={styles.disc}>Disc.</div>
                                        <div className={styles.total}>Total</div>
                                    </div>
                                    <div className={styles.tableBody}>
                                        {invoiceData?.items?.map((item, index) => (
                                            <div key={index} className={styles.rowWrapper}>
                                                <div className={styles.sn}>{index + 1}.</div>
                                                <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.itemNameWithGst : styles.itemNameWithNoGst}>
                                                    {item?.serviceName}
                                                    <div className={styles.serviceDuration} >
                                                        {item?.serviceDuration} {item?.serviceDurationUnit}
                                                    </div>
                                                    <div className={styles.description}>
                                                        {item?.description}
                                                    </div>
                                                </div>
                                                <div className={styles.itemCategory}>
                                                    {capitalizeFirstLetter(item?.categoryName)}
                                                </div>
                                                {/* {invoiceDataToUpdate?.isGstBill === "1" && <div className={styles.batchNo}>-</div>} */}
                                                {invoiceDataToUpdate?.isGstBill === "1" && <div className={styles.hsn}>{item?.hsnCode}</div>}
                                                {invoiceDataToUpdate?.isGstBill === "1" && <div className={styles.gst}>12.00</div>}
                                                <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.mrpWithGst : styles.mrpWithNoGst}>{item?.basePrice}</div>
                                                <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.qtyWithGst : styles.qtyWithNoGst}>{item?.quantity}</div>
                                                <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.rateWithGst : styles.rateWithNoGst}>{item?.basePrice}</div>
                                                <div className={styles.amount}>{parseFloat(parseFloat(item?.basePrice) * parseFloat(item?.quantity)).toFixed(1)}</div>
                                                <div className={styles.disc}>{parseFloat(parseFloat(parseFloat(item?.totalBasePrice) - parseFloat(item?.totalDiscPrice)) * parseInt(item?.quantity)).toFixed(2)}</div>
                                                <div className={styles.total}>{parseFloat(parseFloat(item?.totalDiscPrice) * parseInt(item?.quantity)).toFixed(2)}</div>
                                            </div>
                                        ))}

                                    </div>
                                    {parseInt(invoiceData?.flatDiscountByAmount) > 0 &&
                                        <div className={styles.gtDiscount}>
                                            <div className={styles.gtTag}>Flat Discount : </div>
                                            <div className={styles.gtVal}>₹{parseFloat(invoiceData?.flatDiscountByAmount)}</div>
                                        </div>
                                    }
                                </div>

                                <div className={styles.totalRow}>
                                    <div className={styles.trTotal}>
                                        Total
                                    </div>
                                    <div className={`${styles?.trTValue} ${invoiceDataToUpdate?.isGstBill === "1" ? styles.qtyWithGst : styles.qtyWithNoGst}`}>
                                        {sumQuantities(invoiceData?.items)}
                                    </div>
                                    <div className={invoiceDataToUpdate?.isGstBill === "1" ? styles.rateWithGst : styles.rateWithNoGst}>

                                    </div>
                                    <div className={styles.amount}>
                                        {/* {parseFloat(invoiceData?.servicesTotalOrgPrice).toFixed(2)} */}
                                        {getItemsTotal(invoiceData?.items)}
                                    </div>
                                    <div className={styles.disc}>
                                        {/* {parseFloat(parseFloat(invoiceData?.servicesTotalOrgPrice) - parseFloat(invoiceData?.amountToBeCollected)).toFixed(2)} */}
                                        {parseFloat(getItemsTotalDiscount(invoiceData?.items) + parseFloat(invoiceData?.flatDiscountByAmount)).toFixed(2)}
                                    </div>
                                    <div className={styles.trTotalT}>
                                        {/* ₹{parseFloat(invoiceData?.amountToBeCollected).toFixed(2)} */}
                                        ₹{parseFloat(getItemsTotalAfterDiscount(invoiceData?.items) - parseFloat(invoiceData?.flatDiscountByAmount)).toFixed(2)}
                                    </div>
                                </div>

                                <div className={styles.gstNTotalWrapper}>
                                    <div className={styles.amtInWordsWrapper}>
                                        <div className={styles.amt}>
                                            <div className={styles.greenTxt}>
                                                Paid Amount in Words : {numberToWords((parseInt(invoiceData?.collectionViaCash) || 0) + (parseInt(invoiceData?.collectionViaCard) || 0) + (parseInt(invoiceData?.collectionViaQR) || 0))} rupees only.
                                            </div>
                                            {parseInt(invoiceData?.remainingAmount) > 0 &&
                                                <div className={styles.redTxt}>
                                                    Due Amount in Words : {numberToWords(parseInt(invoiceData?.remainingAmount))} rupees.
                                                </div>
                                            }
                                        </div>
                                        {invoiceDataToUpdate?.isGstBill === "1" &&
                                            invoiceDataToUpdate?.showTaxTableInInvoice === "1" &&
                                            <div className={styles.taxTableWrapper}>
                                                <div className={styles.taxTableContainer}>
                                                    <div className={styles.ttTaxableValue}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            Taxable Value
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)}
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)}
                                                        </div>
                                                    </div>
                                                    <div className={styles.ttCgst}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            <div className={styles.ttwubUpper}>
                                                                CGST
                                                            </div>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >%</div>
                                                                <div className={styles.ttwubAmount} >Amt</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >6</div>
                                                                <div className={styles.ttwubAmount} >
                                                                    {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2) / 2}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2) / 2}
                                                        </div>
                                                    </div>
                                                    <div className={styles.ttCgst}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            <div className={styles.ttwubUpper}>
                                                                SGST
                                                            </div>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >%</div>
                                                                <div className={styles.ttwubAmount} >Amt</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >6</div>
                                                                <div className={styles.ttwubAmount} >
                                                                    {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2) / 2}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2) / 2}
                                                        </div>
                                                    </div>
                                                    <div className={styles.ttCgst}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            <div className={styles.ttwubUpper}>
                                                                IGST
                                                            </div>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >%</div>
                                                                <div className={styles.ttwubAmount} >Amt</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >0</div>
                                                                <div className={styles.ttwubAmount} >.00</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}></div>
                                                    </div>
                                                    <div className={styles.ttTgst}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            Total GST
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2)}
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.gtWrapper}>

                                        {invoiceDataToUpdate?.isGstBill === "1" && <div className={styles.gtBeforeTax}>
                                            <div className={styles.gtTag}>Before Tax : </div>
                                            <div className={styles.gtVal}>
                                                {parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)}
                                            </div>
                                        </div>}
                                        {invoiceDataToUpdate?.isGstBill === "1" &&
                                            <div className={styles.totalGst}>
                                                <div className={styles.gtTag}>Total GST : </div>
                                                <div className={styles.gtVal}>
                                                    {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2)}
                                                </div>
                                            </div>
                                        }

                                        <div className={styles.gtRound}>
                                            <div className={styles.gtTag}>Round Off : </div>
                                            <div className={styles.gtVal}>
                                                {roundOff(invoiceData?.amountToBeCollected)?.difference || 0}
                                            </div>
                                        </div>
                                        <div className={styles.gtNetAmt}>
                                            <div className={styles.gtTag}>Net Amount : </div>
                                            <div className={styles.gtVal}>₹{parseInt(invoiceData?.amountToBeCollected)}</div>
                                        </div>
                                        <div className={styles.paymentModeWrapper}>
                                            {invoiceData?.collectionViaCash !== "0" &&
                                                <div className={styles.gtRound}>
                                                    <div className={styles.gtTag}>Cash Payment : </div>
                                                    <div className={styles.gtVal}>{invoiceData?.collectionViaCash}</div>
                                                </div>
                                            }
                                            {invoiceData?.collectionViaQR !== "0" &&
                                                <div className={styles.gtRound}>
                                                    <div className={styles.gtTag}>QR Payment : </div>
                                                    <div className={styles.gtVal}>{invoiceData?.collectionViaQR}</div>
                                                </div>
                                            }
                                            {invoiceData?.collectionViaCard !== "0" &&
                                                <div className={styles.gtRound}>
                                                    <div className={styles.gtTag}>Card Payment : </div>
                                                    <div className={styles.gtVal}>{invoiceData?.collectionViaCard}</div>
                                                </div>
                                            }
                                            {parseInt(invoiceData?.remainingAmount) !== 0 &&
                                                <div className={styles.gtPaymentDue}>
                                                    <div className={styles.gtTag}>Payment Due : </div>
                                                    <div className={styles.gtVal}>₹{invoiceData?.remainingAmount}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tncWrapper}>
                                    <div className={styles.tnc}>
                                        <div className={styles.tncTitle}>
                                            Notes :
                                        </div>
                                        <div className={styles.tncText}
                                            dangerouslySetInnerHTML={{ __html: invoiceDataToUpdate?.invoiceTnc }}
                                        >
                                        </div>
                                    </div>
                                    <div className={styles.storeAuthName}>
                                        <div className={styles.forStoreName}>For {activeStore?.storeName}</div>
                                        {/* <div>{invoiceData?.createdBy}</div> */}
                                        <div className={styles.authSignatory}>Authorized Signature</div>
                                    </div>
                                </div>


                                <div className={styles.disclaimerline}>
                                    This is a system generated invoice. You can use {invoiceIdentifier} key to track in future.
                                </div>
                                <div className={styles.invoiceFooter}>
                                    <div className={`${styles.ifLeftCol} ${styles.eachIfCol}`}>
                                        <div className={styles.ifColTitle}>Reach Us At</div>
                                        <div className={styles.ifColVal}>
                                            <div className={styles.eachIfColVal}>
                                                <MdEmail size={14} />&nbsp;{activeStore?.storeEmail}
                                            </div>
                                            <div>
                                                <div>
                                                    <FaPhoneAlt size={14} />&nbsp;+91-{activeStore?.storePrimaryPhone}
                                                </div>
                                                {activeStore?.storeSecondaryPhone !== null &&
                                                    <div>
                                                        <FaPhoneAlt size={14} />&nbsp;{activeStore?.storeSecondaryPhone}
                                                    </div>
                                                }
                                                {activeStore?.storeTertiaryPhone !== '' &&
                                                    <div>
                                                        <FaPhoneAlt size={14} />&nbsp;+91-{activeStore?.storeTertiaryPhone}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.ifRightCol} ${styles.eachIfCol}`}>
                                        <div className={styles.ifColTitle}>Our Branch(s)</div>
                                        <div className={styles.ifColVal}>
                                            {generateBillThroughOptions.filter((item) => item?.storeIdentifier !== invoiceDataToUpdate?.gbtIdentifier)?.map((itemNew, indexNew) => (
                                                <div key={indexNew} className={styles.eachBranchDetails} >
                                                    <div className={styles.eachIfColVal}>
                                                        <MdLocationPin />&nbsp;
                                                        {itemNew?.storeName}
                                                    </div>
                                                    <div>
                                                        {itemNew?.storeAddress}
                                                    </div>
                                                    <div>
                                                        {itemNew?.storeEmail}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                    <div className={styles.mainFooter}>
                        {hasEdited === true ?
                            <button onClick={() => updatePdfHandler()} className={styles.downloadPdfBtn} > <IoMdCloudDownload size={18} />&nbsp;Update Invoice</button>
                            :
                            <button onClick={() => downloadPdfHandler()} className={styles.downloadPdfBtn} > <IoMdCloudDownload size={18} />&nbsp;Download Pdf</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewInvoice;
