// Utility function to convert an image URL to a base64 string
// const proxyUrl = "https://cors-anywhere.herokuapp.com/";
const proxyUrl = "";
export const urlToBase64 = async (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // Allow cross-origin access
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const base64String = canvas.toDataURL("image/png");
            resolve(base64String);
        };
        img.onerror = (err) => {
            reject(`Failed to load image at ${url}: ${err}`);
        };
        // img.src = url;
        img.src = `${proxyUrl}${url}`; // Fetch the image through the proxy
    });
};