import React from "react";
import styles from "./InvoiceSelector.module.css";

import CorporateBlueTheme from "./CorporateBlueTheme";
import TallyTheme from "./TallyTheme";
import ReleCloudTheme from "./ReleCloudTheme";
import ElegantBlissTheme from "./ElegantBlissTheme";
import HappiestValleyTheme from "./HappiestValleyTheme";
import SleekInvoiceTheme from "./SleekInvoiceTheme";

const InvoiceSelector = ({ selectedPallete, selectedInvoice, invoiceDataMaster }) => {


    const invoices = [
        { id: "CorporateBlue", name: "Corporate Blue Theme", component: <CorporateBlueTheme invoiceDataMaster={invoiceDataMaster} themeData={selectedInvoice} selectedPalette={selectedPallete} /> },
        { id: "TallyTheme", name: "Tally Theme", component: <TallyTheme invoiceDataMaster={invoiceDataMaster} themeData={selectedInvoice} selectedPalette={selectedPallete} /> },
        { id: "ReleCloudTheme", name: "Rele Cloud Theme", component: <ReleCloudTheme invoiceDataMaster={invoiceDataMaster} themeData={selectedInvoice} selectedPalette={selectedPallete} /> },
        { id: "ElegantBlissTheme", name: "Elegant Bliss Theme", component: <ElegantBlissTheme invoiceDataMaster={invoiceDataMaster} themeData={selectedInvoice} selectedPalette={selectedPallete} /> },
        { id: "HappiestValleyTheme", name: "Elegant Bliss Theme", component: <HappiestValleyTheme invoiceDataMaster={invoiceDataMaster} themeData={selectedInvoice} selectedPalette={selectedPallete} /> },
        { id: "SleekInvoiceTheme", name: "Elegant Bliss Theme", component: <SleekInvoiceTheme invoiceDataMaster={invoiceDataMaster} themeData={selectedInvoice} selectedPalette={selectedPallete} /> },
    ];

    return (
        <div className={styles.selectorWrapper}>
            {/* Invoice Preview */}
            <div className={styles.preview}
                // id="invoicePreview"
            >
                {invoices.find((invoice) => invoice.id === selectedInvoice?.id)?.component}
            </div>
        </div>
    );
};

export default InvoiceSelector;
