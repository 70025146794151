import React, { useMemo } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { motion } from "framer-motion";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from "chart.js";
import styles from './MembershipAnalytics.module.css'
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const MembershipAnalytics = ({ data }) => {
    const analytics = useMemo(() => {
        const totalMemberships = data?.length || 0;

        // Calculate total revenue, handle edge cases with initial value of 0
        const totalRevenue = data?.reduce((sum, item) => sum + parseFloat(item.amountPaid || 0), 0) || 0;

        const activeMemberships = data?.filter((item) => item.isActive === "1").length || 0;
        const inactiveMemberships = totalMemberships - activeMemberships;

        // Handle expired memberships safely
        const expiredMemberships = data?.filter((item) => new Date(item.expiryDate) < new Date()).length || 0;

        // Calculate payment mode counts with an initial empty object
        const paymentModeCounts = data?.reduce((acc, item) => {
            const mode = item.payMode || "Unknown";
            acc[mode] = (acc[mode] || 0) + 1;
            return acc;
        }, {});

        const mostCommonPaymentMode = Object.keys(paymentModeCounts || {}).reduce((a, b) =>
            paymentModeCounts[a] > paymentModeCounts[b] ? a : b,
            "N/A" // Default value if no data
        );

        return {
            totalMemberships,
            totalRevenue,
            activeMemberships,
            inactiveMemberships,
            expiredMemberships,
            mostCommonPaymentMode,
            paymentModeCounts,
        };
    }, [data]);

    const pieData = {
        labels: ["Active", "Inactive", "Expired"],
        datasets: [
            {
                label: "Memberships",
                data: [
                    analytics.activeMemberships,
                    analytics.inactiveMemberships,
                    analytics.expiredMemberships,
                ],
                backgroundColor: ["#4caf50", "#ff9800", "#f44336"],
                hoverOffset: 4,
            },
        ],
    };

    const barData = {
        labels: Object.keys(analytics.paymentModeCounts || {}),
        datasets: [
            {
                label: "Payment Modes",
                data: Object.values(analytics.paymentModeCounts || {}),
                backgroundColor: ["#2196f3", "#e91e63", "#ffc107", "#9c27b0"],
            },
        ],
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            style={{
                padding: "20px",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            className={styles.mainWrapper}
        >
            <motion.h2
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}
            >
                Membership Analytics
            </motion.h2>

            <motion.div
                style={{ display: "flex", flexDirection: "column", gap: "15px", marginBottom: "20px" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
            >
                <div><strong>Total Memberships:</strong> {analytics.totalMemberships}</div>
                <div><strong>Total Revenue:</strong> ₹{analytics.totalRevenue.toFixed(2)}</div>
                <div><strong>Active Memberships:</strong> {analytics.activeMemberships}</div>
                <div><strong>Inactive Memberships:</strong> {analytics.inactiveMemberships}</div>
                <div><strong>Expired Memberships:</strong> {analytics.expiredMemberships}</div>
                <div><strong>Most Common Payment Mode:</strong> {analytics.mostCommonPaymentMode}</div>
            </motion.div>

            <motion.div
                style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "20px" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8 }}
            >
                <div style={{ flex: "1 1 300px" }}>
                    <h3>Membership Status</h3>
                    <Pie data={pieData} />
                </div>
                <div style={{ flex: "1 1 300px" }}>
                    <h3>Payment Modes</h3>
                    <Bar data={barData} options={{ responsive: true }} />
                </div>
            </motion.div>
        </motion.div>
    );
};

export default MembershipAnalytics;
