import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./ClientSearchDropdown.module.css";
import { BASE_URL_API } from "../../../../References/URLs";
import AuthContext from "../../../../store/auth-context";
import ActiveStoreContext from "../../../../store/ActiveStoreContext";
import AddNewClient from "../../../Users/AddNewClient";

const ClientSearchDropdown = ({ invoiceDataMaster, setInvoiceDataMaster }) => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();


    // const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm, setSearchTerm] = useState();
    const [suggestions, setSuggestions] = useState([]);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isSearching, setIsSearching] = useState(false)
    const [isNewClientOpen, setIsNewClientOpen] = useState(false)

    // console.log(invoiceDataMaster?.clientData?.client_name)

    useEffect(() => {
        if (searchTerm?.trim()) {
            const fetchSuggestions = async () => {
                try {
                    setIsSearching(true)
                    const getClientViaSearchResponse = await fetch(BASE_URL_API + "getClientViaSearch",
                        {
                            method: 'POST',
                            body: JSON.stringify({
                                token: authCtx.token,
                                storeIdentifier: activeStore?.storeIdentifier,
                                searchKey: searchTerm
                            })
                        });

                    if (!getClientViaSearchResponse.ok) {
                        console.log("Something went wrong : Server Error")
                    } else {
                        const getClientViaSearchRespo = await getClientViaSearchResponse.json();
                        if (getClientViaSearchRespo.status === 'success') {
                            setSuggestions(getClientViaSearchRespo.response);
                        } else {
                            if (getClientViaSearchRespo.message === 'tokenExpired') {
                                authCtx.logout()
                            } else {
                                setSuggestions([])
                            }
                        }
                        console.log(getClientViaSearchRespo)
                    }
                    setIsSearching(false)


                } catch (error) {
                    console.error("Error fetching suggestions:", error);
                    setSuggestions([]);
                }
            };

            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchTerm]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setActiveIndex((prevIndex) => Math.min(prevIndex + 1, suggestions.length));
        } else if (e.key === "ArrowUp") {
            setActiveIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (e.key === "Enter") {
            if (activeIndex === -1) {
                updateClientData({ clientName: searchTerm });
            } else if (activeIndex === 0) {
                updateClientData({ clientName: "Add New Client" });
            } else {
                updateClientData(suggestions[activeIndex - 1]);
            }
            setDropdownVisible(false);
        }
    };

    const updateClientData = (client) => {
        if(client.clientName==='Add New Client') {
            setIsNewClientOpen(true)
        } else {
            setInvoiceDataMaster((prev) => ({
                ...prev,
                clientData: { ...prev.clientData, ...client },
            }));
            setSearchTerm(client.clientName || "");
        }
    };

    const handleBlur = () => {
        if (activeIndex === -1) {
            updateClientData({ clientName: searchTerm });
        }
        setDropdownVisible(false);
    };

    return (
        <div className={styles.searchDropdown}>
            <AddNewClient 
                isOpen={isNewClientOpen}
                closeModal={() => setIsNewClientOpen(false)}
                // assignClient={} 
            />
            <input
                type="text"
                placeholder="Search with client name or number..."
                value={searchTerm ?? invoiceDataMaster?.clientData?.client_name}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setDropdownVisible(true)}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                className={styles.searchInput}
            />
            <AnimatePresence>
                {isDropdownVisible && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className={styles.suggestionsList}
                    >
                        <div
                            className={`${styles.suggestionItem} ${activeIndex === 0 ? styles.active : ""
                                }`}
                            onMouseDown={() => updateClientData({ clientName: "Add New Client" })}
                        >
                            Add New Client
                        </div>
                        {suggestions?.map((client, index) => (
                            <div
                                key={index}
                                className={`${styles.suggestionItem} ${activeIndex === index + 1 ? styles.active : ""
                                    }`}
                                onMouseDown={() => updateClientData(client)}
                            >
                                {client.clientName} ({client.mobile})
                            </div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ClientSearchDropdown;
