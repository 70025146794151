import React, { useState, useEffect } from "react";
import styles from "./TemplatePreview.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineMessage } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

// Helper function to replace variables with actual values
const replaceVariables = (template) => {
    if (!template || !template.content) return "";
    let message = template.content;

    template.variables.forEach((variable) => {
        const placeholder = `{${variable.name}}`;
        message = message.replace(
            new RegExp(placeholder, "g"),
            variable.value || variable.defaultValue || ""
        );
    });

    return message;
};

const TemplatePreview = ({ visible, onClose, template, onTemplateSelect, data, setData }) => {
    const [previewText, setPreviewText] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState(template); // Track selected template separately
    const [errorMessage, setErrorMessage] = useState(""); // Track error messages

    // Function to reset template after change
    const resetTemplateState = (template) => {
        const resetTemplate = {
            ...template,
            variables: template.variables.map((variable) => ({
                ...variable,
                value: variable.value || "", // Reset variable value to an empty string if it's empty
            })),
        };
        return resetTemplate;
    };

    // Ensures template is updated if template changes (first time or new template selected)
    useEffect(() => {
        if (template && template !== selectedTemplate) {
            setSelectedTemplate(template);
        }
    }, [template]);

    // Update previewText whenever selectedTemplate changes
    useEffect(() => {
        if (selectedTemplate) {
            const updatedMessage = replaceVariables(selectedTemplate);
            setPreviewText(updatedMessage);
        }
    }, [selectedTemplate]);

    // Handle changes in the variable values
    const handleVariableChange = (variableName, value) => {
        const updatedVariables = selectedTemplate.variables.map((variable) =>
            variable.name === variableName
                ? { ...variable, value } // Update the specific variable value
                : variable
        );

        const updatedTemplate = { ...selectedTemplate, variables: updatedVariables };
        const updatedMessage = replaceVariables(updatedTemplate);

        setPreviewText(updatedMessage);
        setSelectedTemplate(updatedTemplate); // Update the selected template state

        setData({
            ...data,
            messageTemplate: updatedTemplate, // Store the updated template
            message: updatedMessage, // Store the updated message
        });
    };

    // Function to check if all required fields are filled
    const areAllRequiredFieldsFilled = () => {
        return selectedTemplate.variables.every(
            (variable) => !variable.required || (variable.required && variable.value !== "")
        );
    };

    // Handle the select template button click
    const handleSelectTemplate = () => {
        console.log("Selected Template:", selectedTemplate);
        
        // Check if all required fields are filled
        if (!areAllRequiredFieldsFilled()) {
            setErrorMessage("Please fill all required variables before selecting the template.");
            return;
        }

        // Reset the template values if all variables are filled
        const resetTemplate = resetTemplateState(selectedTemplate);

        setSelectedTemplate(resetTemplate); // Reset selected template
        setData({
            ...data,
            messageTemplate: resetTemplate, // Reset message template
            message: replaceVariables(resetTemplate), // Reset message
        });

        onTemplateSelect(resetTemplate); // Notify parent of the selected template
        setErrorMessage(""); // Clear error message
    };

    if (!visible || !selectedTemplate) return null;

    const handleOverlayClick = () => onClose();
    const handleContentClick = (e) => e.stopPropagation();

    return (
        <div className={styles.previewOverlay} onClick={handleOverlayClick}>
            <div className={styles.previewContent} onClick={handleContentClick}>
                <button className={styles.closeBtn} onClick={onClose}>
                    <AiOutlineClose />
                </button>
                <div className={styles.layout}>
                    {/* Left Column: Variables */}
                    <div className={styles.leftColumn}>
                        <h3 className={styles.title}>Fill Variables</h3>
                        <div className={styles.variablesContainer}>
                            {selectedTemplate?.variables?.map((variable) => (
                                <div className={styles.variableRow} key={variable.name}>
                                    <label>{variable?.description || variable?.name}</label>
                                    <input
                                        type={variable.type === "number" ? "number" : "text"}
                                        className={styles.variableInput}
                                        placeholder={`Enter ${variable.name}`}
                                        value={variable.value || ""}
                                        onChange={(e) =>
                                            handleVariableChange(variable.name, e.target.value)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                        <button
                            className={styles.selectButton}
                            onClick={handleSelectTemplate} // Handle template selection
                        >
                            <MdOutlineMessage />
                            Select Template
                        </button>
                    </div>

                    {/* Right Column: Preview */}
                    <div className={styles.rightColumn}>
                        <h3 className={styles.title}>Message Preview</h3>
                        <div className={styles.messagePreview}>
                            {selectedTemplate.deliverySource === "WhatsApp" && (
                                <FaWhatsapp className={styles.icon} />
                            )}
                            <p>{previewText}</p>
                        </div>
                        {/* Metadata Display */}
                        <div className={styles.metadata}>
                            <h4>Metadata:</h4>
                            <ul>
                                <li>Category: {selectedTemplate.category}</li>
                                <li>Language: {selectedTemplate.language}</li>
                                <li>Status: {selectedTemplate.status}</li>
                                <li>
                                    Last Edited: {selectedTemplate?.lastEdited?.date} by{" "}
                                    {selectedTemplate?.lastEdited?.by}
                                </li>
                                <li>Created On: {selectedTemplate?.createdOn}</li>
                                <li>
                                    Expiry Date: {selectedTemplate?.metadata?.expiryDate || "N/A"}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemplatePreview;
