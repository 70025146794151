import React, { useState, useEffect, useContext } from 'react';
import styles from './MessageTemplates.module.css';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import TemplatePreview from './TemplatePreview';
import { BASE_URL_API } from '../../../References/URLs';
import AuthContext from '../../../store/auth-context';
import ActiveStoreContext from '../../../store/ActiveStoreContext';

const MessageTemplates = ({ data, setData }) => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [templates, setTemplates] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTemplate, setPreviewTemplate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        category: '',
        language: '',
        deliverySource: '',
        status: '',
        search: '',
    });
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    useEffect(() => {
        const fetchTemplates = async () => {
            const templateData = [
                {
                    name: 'Welcome Message',
                    deliverySource: 'SMS',
                    category: 'Greeting',
                    language: 'English',
                    status: 'Active',
                    lastEdited: {
                        date: '2025-01-01',
                        by: 'John Doe',
                    },
                    createdOn: '2024-12-01',
                    variables: [
                        { name: 'name', type: 'string', required: true, defaultValue: '', value: '' },
                        { name: 'date', type: 'date', required: true, defaultValue: '', value: '' }
                    ],
                    content: 'Hello {name}, welcome to our platform! Your registration date is {date}.',
                    actions: [{ type: 'button', label: 'Shop Now', url: 'https://example.com/shop' }],
                    metadata: { expiryDate: '2025-01-31', termsAndConditions: 'https://example.com/terms' }
                },
                {
                    name: 'Discount Offer',
                    deliverySource: 'WhatsApp',
                    category: 'Promotion',
                    language: 'English',
                    status: 'Inactive',
                    lastEdited: { date: '2024-12-20', by: 'Jane Smith' },
                    createdOn: '2024-11-15',
                    variables: [
                        { name: 'discount', type: 'number', required: true, defaultValue: 10, value: '' },
                        { name: 'expiryDate', type: 'date', required: true, defaultValue: '', value: '' }
                    ],
                    content: 'Hi! Enjoy a {discount}% discount at our store. Offer valid till {expiryDate}. Don’t miss out!',
                    actions: [{ type: 'button', label: 'Claim Offer', url: 'https://example.com/offer' }],
                    metadata: { expiryDate: '2024-12-31', termsAndConditions: 'https://example.com/offer-terms' }
                },
            ];
            setTemplates(templateData);
            setFilteredTemplates(templateData);
        };
        // fetchTemplates();
        getAllTemplates()
    }, []);

    useEffect(() => {
        // If selectedTemplate is set, update the message preview
        if (selectedTemplate) {
            const updatedMessage = handleVariableChange(selectedTemplate, selectedTemplate.variables.reduce((acc, v) => {
                acc[v.name] = v.value || v.defaultValue;
                return acc;
            }, {}));
            setData({ ...data, messageTemplate: selectedTemplate, message: updatedMessage });
        }
    }, [selectedTemplate]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const sortedTemplates = [...filteredTemplates].sort((a, b) => {
            const valA = a[key] || '';
            const valB = b[key] || '';
            if (typeof valA === 'string' && typeof valB === 'string') {
                return direction === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
            }
            return direction === 'asc' ? valA - valB : valB - valA;
        });
        setFilteredTemplates(sortedTemplates);
        setSortConfig({ key, direction });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const applyFilters = () => {
        const { category, language, deliverySource, status, search } = filters;
        const filtered = templates.filter((template) => {
            return (
                (!category || template.category?.toLowerCase().includes(category.toLowerCase())) &&
                (!language || template.language?.toLowerCase().includes(language.toLowerCase())) &&
                (!deliverySource || template.deliverySource?.toLowerCase().includes(deliverySource.toLowerCase())) &&
                (!status || template.status?.toLowerCase() === status.toLowerCase()) &&
                (!search || template.name?.toLowerCase().includes(search.toLowerCase()))
            );
        });
        setFilteredTemplates(filtered);
        setCurrentPage(1);
    };

    useEffect(() => {
        applyFilters()
    }, [filters])
    

    const handleVariableChange = (template, variableValues) => {
        if (!template || !template.content) return '';
        return template.content.replace(/{(\w+)}/g, (_, key) => variableValues[key] || `{${key}}`);
    };

    const handleTemplateSelection = (template) => {
        if (!template) return;
        const allVariablesFilled = template.variables.every((v) => !v.required || v.value?.trim());
        if (!allVariablesFilled) {
            alert('Please fill all required variables before selecting the template.');
            return;
        }

        // Check if the template is already selected
        if (selectedTemplate && selectedTemplate.templateIdentifier === template.templateIdentifier) {
            alert('This template is already selected.');
            return;
        }

        const updatedMessage = handleVariableChange(template, template.variables.reduce((acc, v) => {
            acc[v.name] = v.value || v.defaultValue;
            return acc;
        }, {}));
        setSelectedTemplate(template);
        setPreviewVisible(false);
        setData({ ...data, messageTemplate: template, message: updatedMessage });
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (direction) => {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next' && currentPage < Math.ceil(filteredTemplates.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const paginatedTemplates = filteredTemplates.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );


    const getAllTemplates = async () => {
        const getAllTemplatesResponse = await fetch(BASE_URL_API + "getAllTemplates", 
        {
            method: "POST",
            body: JSON.stringify({
                token: authCtx.token,
            })
        });

        if (!getAllTemplatesResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getAllTemplatesRespo = await getAllTemplatesResponse.json();
            if (getAllTemplatesRespo.status === "success") {
                setTemplates(getAllTemplatesRespo?.response);
                setFilteredTemplates(getAllTemplatesRespo?.response);
            } else if (getAllTemplatesRespo.message === "tokenExpired") {
                authCtx.logout();
            } else {
                setTemplates([]);
                setFilteredTemplates([]);
            }
            console.log(getAllTemplatesRespo);
        }
    };

    return (
        <div className={styles.container}>
            <h2>Message Templates</h2>
            <div className={styles.toolbar}>
                <input
                    type="text"
                    placeholder="Search templates..."
                    name="search"
                    onChange={handleFilterChange}
                />
                <button onClick={applyFilters}>Apply Filters</button>
                <select name="category" onChange={handleFilterChange}>
                    <option value="">Category</option>
                    <option value="Greeting">Greeting</option>
                    <option value="Promotion">Promotion</option>
                </select>
                <select name="language" onChange={handleFilterChange}>
                    <option value="">Language</option>
                    <option value="English">English</option>
                </select>
                <select name="deliverySource" onChange={handleFilterChange}>
                    <option value="">Delivery Source</option>
                    <option value="SMS">SMS</option>
                    <option value="WhatsApp">WhatsApp</option>
                </select>
                <select name="status" onChange={handleFilterChange}>
                    <option value="">Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                </select>
                <select onChange={handleRowsPerPageChange}>
                    <option value={5}>5 Rows</option>
                    <option value={10}>10 Rows</option>
                    <option value={20}>20 Rows</option>
                </select>
            </div>
            <div className={styles.table}>
                <div className={styles.header}>
                    <div>Radio</div>
                    <div onClick={() => handleSort('name')}>Template Name</div>
                    <div onClick={() => handleSort('deliverySource')}>Delivery Source</div>
                    <div>Category</div>
                    <div>Language</div>
                    <div>Status</div>
                    <div onClick={() => handleSort('lastEdited.date')}>Last Edited</div>
                    <div>Preview</div>
                </div>
                <div className={styles.body}>
                    {paginatedTemplates.map((template) => (
                        <div key={template.name} className={styles.row}>
                            <div>
                                {selectedTemplate?.templateIdentifier === template.templateIdentifier ? (
                                    <MdOutlineRadioButtonChecked />
                                ) : (
                                    <MdOutlineRadioButtonUnchecked
                                        onClick={() => handleTemplateSelection(template)}
                                    />
                                )}
                            </div>
                            <div>{template.templateNamePlatform}</div>
                            <div>{template.deliverySource}</div>
                            <div>{template.category}</div>
                            <div>{template.language}</div>
                            <div>{template.status}</div>
                            <div>{template.lastEdited?.date}</div>
                            <div>
                                <button
                                    onClick={() => {
                                        setPreviewTemplate(template);
                                        setPreviewVisible(true);
                                    }}
                                >
                                    Preview
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.pagination}>
                <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>
                    Page {currentPage} of {Math.ceil(filteredTemplates.length / rowsPerPage)}
                </span>
                <button
                    onClick={() => handlePageChange('next')}
                    disabled={currentPage === Math.ceil(filteredTemplates.length / rowsPerPage)}
                >
                    Next
                </button>
            </div>
            {previewVisible && (
                <TemplatePreview
                    visible={previewVisible}
                    onClose={() => setPreviewVisible(false)}
                    template={previewTemplate}
                    onVariableChange={handleVariableChange}
                    onTemplateSelect={handleTemplateSelection}
                    data={data}
                    setData={setData}
                />
            )}
        </div>
    );
};

export default MessageTemplates;
