import React from "react";
import styles from "./CorporateBlueTheme.module.css";

const CorporateBlueTheme = () => {
    return (
        <div className={styles.corporateWrapper}>
            {/* Header */}
            <header className={styles.header}>
                <h1>INVOICE</h1>
                <div>
                    <h2>Company Name</h2>
                    <p>City, State ZIP</p>
                </div>
            </header>

            {/* Customer Info */}
            <section className={styles.customerInfo}>
                <p>Customer Name</p>
                <p>City, State ZIP</p>
            </section>

            {/* Line Items */}
            <section className={styles.lineItems}>
                <table>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Item A</td>
                            <td>1</td>
                            <td>$100</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
    );
};

export default CorporateBlueTheme;
