import React, { useContext, useEffect, useState } from 'react'
import { FaCalendar, FaChevronDown } from 'react-icons/fa'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import DateRangeSelector from '../../Components/UI/DateRangeSelector'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../StaffManagement/ManageStaff.module.css'
import { Link } from 'react-router-dom'
import SelectStaff from './SelectStaff'
import StarPerformers from '../../Components/StaffManagement/StarPerformers'
import ManageEachStaffWrapper from '../../Components/StaffManagement/ManageEachStaffWrapper'
import ActiveStoreContext from '../../store/ActiveStoreContext'

const ManageStaff = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();


    const [selectedStaff, setSelectedStaff] = useState('Summary')


    const [isStaffOpen, setIsStaffOpen] = useState(true)
    const closeStaffPopup = () => setIsStaffOpen(false)

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }

    const staffSelectingHandler = (k) => {
        setSelectedStaff(k)
        closeStaffPopup()
    }


    if(activeStore===null) {
        return(
            <div>
                Please select a store.
            </div>
        )
    }



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.headerCaption}>
                    Staff Manager
                </div>
                <div className={styles.actionHeader}>
                    <div className={styles.chooseStaffBtn}>
                        {selectedStaff !== '' ?
                            selectedStaff === 'Summary' ?
                                <button onClick={() => setIsStaffOpen(true)}>Summary &nbsp; <FaChevronDown /></button>
                                :
                                <button onClick={() => setIsStaffOpen(true)}>{selectedStaff.name} &nbsp; <FaChevronDown /></button>
                            :
                            <button onClick={() => setIsStaffOpen(true)}>Select Staff</button>
                        }
                    </div>
                    <div className={styles.dateHeader}>
                        <div className={styles.dateBtn}>
                            <button onClick={() => setIsDatePickerOpen(true)}>
                                From {dates.startDate} to {dates.endDate} &nbsp;
                                <FaCalendar size={14} />
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            {/* <NavAtHome /> */}
            <DateRangeSelector
                isOpen={isDatePickerOpen}
                closeModal={closeModal}
                changeDateHandler={changeDateHandler}
            />
            <SelectStaff
                isOpen={isStaffOpen}
                closeStaffPopup={closeStaffPopup}
                selectedStaff={selectedStaff}
                staffSelectingHandler={staffSelectingHandler}
                closeModal={closeModal}
            />

            <div className={styles.mainBody}>
                {selectedStaff === 'Summary' ?
                    <StarPerformers 
                        dates={dates}
                    />
                    :
                    <ManageEachStaffWrapper
                        dates={dates}
                        selectedStaff={selectedStaff}
                    />
                }
            </div>
        </div>
    )
}

export default ManageStaff