import React, { useContext, useEffect, useState } from "react";
import styles from "./CreateAudience.module.css";
import { FaUsers, FaFilter, FaSave, FaSort } from "react-icons/fa";
import { audienceData } from "./AudiencesFormat";
import {
    MdCheckBoxOutlineBlank,
    MdOutlineCheckBox,
    MdRadioButtonChecked,
    MdRadioButtonUnchecked,
} from "react-icons/md";
import EachAudienceCategory from "../../Components/Communications/Audiences/EachAudienceCategory";
import { IoMdCheckboxOutline } from "react-icons/io";
import { BASE_URL_API } from "../../References/URLs";
import AuthContext from "../../store/auth-context";
import ActiveStoreContext from "../../store/ActiveStoreContext";
import { Link } from "react-router-dom";
import SaveAudience from "../../Components/Communications/Audiences/SaveAudience";

const CreateAudience = () => {
    const authCtx = useContext(AuthContext);
    const [currentlySelectedSubCategory, setCurrentlySelectedSubCategory] = useState(null);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return asCtx?.activeStore && asCtx.activeStore.trim() !== ""
                ? JSON.parse(asCtx.activeStore)
                : null;
        } catch {
            return null;
        }
    })();
    const subCategorySelectHandler = (k) => {
        setCurrentlySelectedSubCategory(k);
    };
    const [searchTerm, setSearchTerm] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [audienceDataMaster, setAudienceDataMaster] = useState([])
    const [saveAudienceOpen, setSaveAudienceOpen] = useState(false)

    useEffect(() => {
        if (currentlySelectedSubCategory) getAudienceData();
    }, [currentlySelectedSubCategory]);

    const [audienceDataFetched, setAudienceDataFetched] = useState([]);

    const getAudienceData = async () => {
        console.log(currentlySelectedSubCategory);
        const getAudienceDataResponse = await fetch(BASE_URL_API + "getAudienceData", {
            method: "POST",
            body: JSON.stringify({
                token: authCtx.token,
                audienceTemplateIdentifier: currentlySelectedSubCategory?.audienceIdentifier,
                storeIdentifier: activeStore?.storeIdentifier,
            }),
        });

        if (!getAudienceDataResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getAudienceDataRespo = await getAudienceDataResponse.json();
            if (getAudienceDataRespo.status === "success") {
                setAudienceDataFetched(getAudienceDataRespo?.response);
            } else if (getAudienceDataRespo.message === "tokenExpired") {
                authCtx.logout();
            } else {
                setAudienceDataFetched([])
            }
            console.log(getAudienceDataRespo);
        }
    };

    // Handle Search
    const filteredData = audienceDataFetched.filter((item) =>
        Object.values(item).some((value) =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // Handle Sorting
    const sortData = (key) => {
        const direction =
            sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
        const sortedData = [...filteredData].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });
        setSortConfig({ key, direction });
        setAudienceDataFetched(sortedData);
    };

    // Pagination
    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    // Row Selection
    const toggleRowSelection = (item) => {
        setSelectedRows((prev) => {
            // Check if the item is already selected
            const exists = prev.some((row) => row.clientStoreRelationIdentifier === item.clientStoreRelationIdentifier);
            if (exists) {
                // Remove the item if it is already selected
                return prev.filter((row) => row.clientStoreRelationIdentifier !== item.clientStoreRelationIdentifier);
            } else {
                // Add the item if it is not selected
                return [...prev, item];
            }
        });
    };

    // Select All Rows
    const toggleSelectAll = () => {
        if (selectedRows.length === paginatedData.length) {
            // Deselect all rows
            setSelectedRows([]);
        } else {
            // Select all rows on the current page
            setSelectedRows(paginatedData);
        }
    };

    // Check if a row is selected
    const isRowSelected = (item) => {
        return selectedRows.some((row) => row.clientStoreRelationIdentifier === item.clientStoreRelationIdentifier);
    };

    const pushToAudienceHandler = (newRows) => {
        setAudienceDataMaster((prevMaster) => {
            // Identify unique entries based on clientStoreRelationIdentifier
            const uniqueNewRows = newRows.filter(
                (row) => !prevMaster.some(
                    (existingRow) => existingRow.clientStoreRelationIdentifier === row.clientStoreRelationIdentifier
                )
            );

            // Combine new unique rows with the existing master
            const updatedMaster = [...prevMaster, ...uniqueNewRows];

            // Perform analytics
            const totalAdded = newRows.length; // Total rows in the new batch
            const uniqueAdded = uniqueNewRows.length; // Newly added unique rows
            const totalInMaster = updatedMaster.length; // Total in the master data
            const genderDistribution = updatedMaster.reduce(
                (acc, row) => {
                    acc[row.gender] = (acc[row.gender] || 0) + 1;
                    return acc;
                },
                {}
            );

            // Log analytics (or return them if needed)
            console.log({
                totalAdded,
                uniqueAdded,
                totalInMaster,
                genderDistribution,
            });

            // Return the updated master data
            return updatedMaster;
        });
    };


    if (activeStore === null) {
        return <div>Please select a store first!</div>;
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.hTitle} >
                        <FaUsers className={styles.icon} size={24} /> Create Your Audience
                    </div>
                    <div>
                        <Link to={`/communications/create-campaign/as`}>Create Campaign</Link>
                    </div>
                </div>
                {selectedRows?.length > 0 &&
                    <div className={styles.selectedDataPopup}>
                        <div className={styles.sdpContainer} >
                            <div>
                                {selectedRows?.length} / {audienceDataFetched?.length} rows selected!
                            </div>
                            <div>
                                <button onClick={() => pushToAudienceHandler(selectedRows)} >Push to Audience</button>
                            </div>
                        </div>
                    </div>
                }
                {saveAudienceOpen && <SaveAudience isOpen={saveAudienceOpen} data={audienceDataMaster} onClose={() => setSaveAudienceOpen(false)} />}
                <div className={styles.columnsWrapper}>
                    <div className={styles.leftColumnWrapper}>
                        <div className={styles.leftColumnContainer}>
                            <div>
                                {audienceData?.map((item, index) => (
                                    <EachAudienceCategory
                                        key={index}
                                        item={item}
                                        currentlySelectedSubCategory={currentlySelectedSubCategory}
                                        subCategorySelectHandler={subCategorySelectHandler}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={styles.centerColumnWrapper}>
                        <div className={styles.campaignHeader}>
                            <div className={styles.leftHeader}>
                                <div className={styles.audienceName}>
                                    {currentlySelectedSubCategory?.audienceName} ({audienceDataFetched?.length})
                                </div>
                                <div className={styles.audienceDescription}>
                                    {currentlySelectedSubCategory?.audienceDescription}
                                </div>

                            </div>
                            <div className={styles.rightHeader}>
                                <button className={styles.getDataBtn} onClick={getAudienceData}>
                                    Get Data
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className={styles.toolbar}>
                                <div className={styles.searchBoxWrapper}>
                                    <input
                                        type="text"
                                        className={styles.searchBox}
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div className={styles.paginationControls}>
                                    Rows per page:
                                    <select
                                        value={rowsPerPage}
                                        onChange={(e) => setRowsPerPage(Number(e.target.value))}
                                    >
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={150}>150</option>
                                        <option value={audienceDataFetched?.length}>All</option>
                                    </select>
                                </div>

                                <div className={styles.pagination}>
                                    <button
                                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    >
                                        Previous
                                    </button>
                                    <span>
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button
                                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                            <div className={styles.table}>
                                <div className={styles.tableHeader}>
                                    {paginatedData?.length > 0 &&
                                        <div className={styles.checkBox} onClick={() => toggleSelectAll()} >
                                            {selectedRows.length === paginatedData.length ? <MdOutlineCheckBox size={20} /> : <MdCheckBoxOutlineBlank size={20} />}
                                        </div>
                                    }
                                    <div className={styles.clientName} onClick={() => sortData("client_name")}>
                                        Name <FaSort />
                                    </div>
                                    <div className={styles.mobile} onClick={() => sortData("mobile")}>
                                        Phone <FaSort />
                                    </div>
                                    <div className={styles.gender} onClick={() => sortData("gender")}>
                                        Gender <FaSort />
                                    </div>
                                    <div className={styles.totalSpent} >Spend</div>
                                    <div className={styles.totalVisits}>Visits</div>
                                    <div>Action</div>
                                </div>
                                {paginatedData.length > 0 ? (
                                    <div className={styles.tableData}>
                                        {paginatedData.map((item) => (
                                            <div className={styles.eachDataRow} key={item.clientStoreRelationIdentifier}>
                                                <div className={styles.checkBox} onClick={() => toggleRowSelection(item)} >
                                                    {isRowSelected(item) ?
                                                        <MdOutlineCheckBox size={20} />
                                                        :
                                                        <MdCheckBoxOutlineBlank size={20} />
                                                    }
                                                </div>
                                                <div className={styles.clientName} onClick={() => toggleRowSelection(item)} >{item?.client_name}</div>
                                                <div className={styles.mobile} onClick={() => toggleRowSelection(item)} >+91-{item?.mobile}</div>
                                                <div className={styles.gender} onClick={() => toggleRowSelection(item)}>{item?.gender}</div>
                                                <div className={styles.totalSpent} >
                                                    {item?.TotalSpent}
                                                </div>
                                                <div className={styles.totalVisits}>
                                                    {item?.TotalVisits}
                                                </div>
                                                <div>
                                                    <a
                                                        href={`/user/${item?.clientStoreRelationIdentifier}`}
                                                        target="_blank"
                                                    >
                                                        View Details
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className={styles.tableData}>
                                        <div className={styles.noRecordsFound}>No records found!</div>
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>
                    <div className={styles.rightColumnWrapper}>
                        <div className={styles.rightColumnContainer} >
                            <div>
                                <div>
                                    Audience Data Master
                                </div>
                                <div>
                                    <div>{audienceDataMaster?.length}</div>
                                    <div>Audience Size</div>
                                </div>
                            </div>
                            {audienceDataMaster?.length > 0 &&
                                <button className={styles.button} onClick={() => setSaveAudienceOpen(true)} >
                                    <FaSave className={styles.icon} /> Save Audience
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default CreateAudience;
