import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "./CollectionUpdater.module.css";

const CollectionUpdater = ({ invoiceDataMaster, setInvoiceDataMaster }) => {
    // console.log(invoiceDataMaster?.collectionData)
    const handleCollectionChange = (mode, value) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            collectionData: prevState.collectionData.map((item) =>
                item.mode === mode
                    ? { ...item, amount: parseFloat(value) || 0 }
                    : item
            ),
        }));
    };

    return (
        <motion.div
            className={styles.container}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            {invoiceDataMaster?.collectionData?.map(({ mode, amount }) => (
                <motion.div
                    key={mode}
                    className={styles.card}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <div className={styles.modeLabel}>
                        {`Via ${mode.replace("collectionVia", "")}`}
                    </div>
                    <input
                        type="number"
                        placeholder={`Amount collected via ${mode.replace("collectionVia", "")}`}
                        value={amount}
                        onChange={(e) => handleCollectionChange(mode, e.target.value)}
                        className={styles.input}
                    />
                </motion.div>
            ))}
        </motion.div>
    );
};

export default CollectionUpdater;
