import React, { useContext, useState } from 'react'
import { FaMinus, FaPlus, FaUser } from 'react-icons/fa'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../Sessions/EachItemInCart.module.css'
import RemoveCartItem from './RemoveCartItem'

const EachItemInCart = props => {
    const authCtx = useContext(AuthContext);
    const item = props.item
    const getCartItems = props.getCartItems;
    const [isRemoving, setIsRemoving] = useState(false)

    const quantityChangeHandler = async (k, a) => {
        setIsRemoving(true)
        const updateCartItemQuantityResponse = await fetch(BASE_URL_API + "updateCartItemQuantity",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cartItemIdentifier: k,
                    actionType: a
                })
            });

        if (!updateCartItemQuantityResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateCartItemQuantityRespo = await updateCartItemQuantityResponse.json();
            if (updateCartItemQuantityRespo.status === 'success') {
                getCartItems()
            } else {
                if (updateCartItemQuantityRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    console.log(updateCartItemQuantityRespo)
                }
            }
        }
        setIsRemoving(false)
    }

    return (
        <div className={styles.cartItemWrapper}>
            <div>
                <div>
                    {item.serviceName}
                </div>
                <div>
                    &#8377;{item.baseDiscountedPrice}
                </div>
                <div className={styles.addOn}>
                    {item?.addOns[0]?.addOnLabel} (&#8377;{item?.addOns[0]?.addOnDisPrice})
                </div>
                <div className={styles.staffName}>
                    <FaUser size={10} color="#4b4b4b" />
                    {item?.addOns[0]?.servedByName}
                </div>
            </div>

            {isRemoving === false ?
                <div>
                    <div className={styles.quantityWrapper}>
                        <button onClick={() => quantityChangeHandler(item?.cartItemIdentifier, 'dec')} >
                            <FaMinus size={8} />
                        </button>
                        <div>
                            {item?.quantity}
                        </div>
                        <button onClick={() => quantityChangeHandler(item?.cartItemIdentifier, 'inc')}>
                            <FaPlus size={8} />
                        </button>
                    </div>
                    <div className={styles.servPrice}>
                        &#8377;{parseFloat(parseFloat(item?.totalDiscPrice) * parseFloat(item?.quantity))?.toFixed(0)}/-
                    </div>
                </div>
                :
                <div className={styles.loaderBtn}>
                    <ShimmerThumbnail height={24} rounded />
                </div>
            }
        </div>
    )
}

export default EachItemInCart