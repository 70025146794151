import React, { useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa';
import styles from '../Bookings/ServicesListAtBD.module.css'

const ServicesListAtBD = props => {
    const bookingDetails = props.bookingDetails;


    if (bookingDetails.services === null) {
        return (
            <div>
                No service added yet!
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            {bookingDetails?.services?.map((item, index) => (
                <div key={"m" + index} className={styles.eachServiceWrapper}>
                    <div className={styles.leftCol}>
                        <div className={styles.serviceNameRow}>
                            <div className={styles.serviceName}>
                                {item?.serviceName} x {item?.quantity}
                            </div>
                            <div className={styles.categoryName}>
                                {item?.categoryName}
                            </div>
                            <div className={styles.priceWrapper}>
                                <div className={styles.baseDiscountedPrice}>
                                    &#8377;{item?.baseDiscountedPrice}
                                </div>
                            </div>
                        </div>
                        <div>
                            {item?.addOns?.map((itemAdd, index) => (
                                <div key={index} className={styles.addOnWrapper}>
                                    <div className={styles.addOnLabel}>
                                        {itemAdd?.addOnLabel}
                                    </div>
                                    <div className={styles.addOnPriceWrapper}>
                                        <div className={styles.addOnDisPrice}>
                                            &#8377;{itemAdd?.addOnDisPrice}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.eachServiceFinalPriceWrapper}>
                            <div>
                                Total:
                            </div>
                            <div className={styles.priceWrapper}>
                                {/* <div className={styles.totalOrgPrice}>
                                    &#8377;{parseFloat(item.totalBasePrice) * parseFloat(item.quantity)}
                                </div> */}
                                <div className={styles.totalFinalPrice}>
                                    &#8377;{parseFloat(parseFloat(item?.totalDiscPrice) * parseFloat(item?.quantity)).toFixed(1)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightCol}>
                        {item?.addOns?.map((itemStaff, indexStaff) => (
                            <div className={styles.whoWrapper} key={"Ran" + indexStaff}>
                                <div className={styles.whoWrapperEachCol}>
                                    <div className={styles.whoName}>
                                        {itemStaff?.consultedByName}
                                    </div>
                                    <div className={styles.colTitle}>
                                        Consulted By
                                    </div>
                                </div>
                                <div className={styles.whoWrapperEachCol}>
                                    <div className={styles.whoName}>
                                        {itemStaff?.servedByName}
                                    </div>
                                    <div className={styles.colTitle}>
                                        Served By
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            <div className={styles.thatsAll}>
                That's All!
            </div>

        </div>
    )
}

export default ServicesListAtBD