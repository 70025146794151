import React, { useState, useEffect, useRef } from "react";
import styles from "./ConfigMembershipCNCStoreAndServicesWrapper.module.css";
import { FaCheck, FaTimes } from "react-icons/fa";

const ConfigMembershipCNCStoreAndServicesWrapper = () => {
    const [cardPreview, setCardPreview] = useState({
        redeemableStores: [],
        redeemableServices: [],
    });

    const stores = [
        {
            storeIdentifier: "23423",
            storeName: "The Salon Company",
            storeAddress: "GT-08, Sector - 117, Noida",
        },
        {
            storeIdentifier: "12345",
            storeName: "Salon Bliss",
            storeAddress: "Mall Road, Lucknow",
        },
        {
            storeIdentifier: "67890",
            storeName: "Hair Haven",
            storeAddress: "City Center, Mumbai",
        },
    ]; // Sample stores

    const services = [
        { identifier: "1", label: "Haircut" },
        { identifier: "2", label: "Manicure" },
        { identifier: "3", label: "Pedicure" },
    ]; // Sample services

    const handleStoreSelection = (selectedStores) => {
        setCardPreview((prev) => ({
            ...prev,
            redeemableStores: selectedStores,
        }));
    };

    const handleServiceSelection = (selectedServices) => {
        setCardPreview((prev) => ({
            ...prev,
            redeemableServices: selectedServices,
        }));
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftSection}>
                <h2>Membership Configuration</h2>

                <div className={styles.inputGroup}>
                    <label>Redeemable Stores:</label>
                    <Selector
                        items={stores}
                        selectedItems={cardPreview.redeemableStores}
                        onSelectionChange={handleStoreSelection}
                        displayProperty="storeName"
                        additionalProperty="storeAddress"
                    />
                </div>

                <div className={styles.inputGroup}>
                    <label>Redeemable Services:</label>
                    <Selector
                        items={services}
                        selectedItems={cardPreview.redeemableServices}
                        onSelectionChange={handleServiceSelection}
                        displayProperty="label"
                    />
                </div>
            </div>

            <div className={styles.rightSection}>
                <h2>Preview</h2>
                <p>
                    <strong>Redeemable Stores:</strong>{" "}
                    {cardPreview.redeemableStores.map((store) => store.storeName).join(", ") ||
                        "None"}
                </p>
                <p>
                    <strong>Redeemable Services:</strong>{" "}
                    {cardPreview.redeemableServices.map((service) => service.label).join(", ") ||
                        "None"}
                </p>
            </div>
        </div>
    );
};

const Selector = ({
    items,
    selectedItems,
    onSelectionChange,
    displayProperty,
    additionalProperty,
}) => {
    const [search, setSearch] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const inputRef = useRef(null);

    const filteredItems = items.filter((item) =>
        item[displayProperty].toLowerCase().includes(search.toLowerCase())
    );

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleInputFocus = () => setDropdownOpen(true);

    const handleInputBlur = () => setTimeout(() => setDropdownOpen(false), 150);

    const handleSearchChange = (e) => setSearch(e.target.value);

    const toggleItem = (item) => {
        const updatedItems = selectedItems.some(
            (i) => i[displayProperty] === item[displayProperty]
        )
            ? selectedItems.filter((i) => i[displayProperty] !== item[displayProperty])
            : [...selectedItems, item];
        onSelectionChange(updatedItems);
    };

    const handleKeyDown = (e) => {
        if (!dropdownOpen) return;
        if (e.key === "ArrowDown") {
            setHighlightedIndex((prev) =>
                prev < filteredItems.length - 1 ? prev + 1 : prev
            );
        } else if (e.key === "ArrowUp") {
            setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        } else if (e.key === "Enter" && highlightedIndex >= 0) {
            toggleItem(filteredItems[highlightedIndex]);
        } else if (e.key === "Escape") {
            setDropdownOpen(false);
        }
    };

    const selectDeselectAll = () => {
        if (selectedItems.length === items.length) {
            onSelectionChange([]);
        } else {
            onSelectionChange(items);
        }
    };

    return (
        <div
            className={styles.selector}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            ref={inputRef}
        >
            <input
                type="text"
                placeholder="Search and select"
                value={search}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onChange={handleSearchChange}
            />
            <button
                className={styles.clearButton}
                onClick={() => onSelectionChange([])}
            >
                <FaTimes />
            </button>
            {dropdownOpen && (
                <div className={styles.dropdown}>
                    <div className={styles.selectAll} onClick={selectDeselectAll}>
                        <FaCheck />{" "}
                        {selectedItems.length === items.length
                            ? "Deselect All"
                            : "Select All"}
                    </div>
                    {filteredItems.length > 0 ? (
                        filteredItems.map((item, index) => (
                            <div
                                key={item.identifier || item.storeIdentifier}
                                className={`${styles.dropdownItem} ${selectedItems.includes(item) ? styles.selected : ""
                                    } ${highlightedIndex === index ? styles.highlighted : ""}`}
                                onMouseDown={() => toggleItem(item)}
                            >
                                {item[displayProperty]}
                                {additionalProperty && (
                                    <div className={styles.additionalInfo}>
                                        {item[additionalProperty]}
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className={styles.noResults}>No results found</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ConfigMembershipCNCStoreAndServicesWrapper;
