import React, { useEffect, useState } from "react";
import { urlToBase64 } from "../../../Helper/utility";
import styles from "./ReleCloudTheme.module.css";

const ReleCloudTheme = ({ selectedPalette, invoiceDataMaster, themeData }) => {
    const { meta } = themeData;
    const {
        clientData,
        productData,
        totalAmount,
        tncData,
        customFields,
        invoiceDate,
        dueDate,
    } = invoiceDataMaster;

    const [base64Logo, setBase64Logo] = useState(null);

    useEffect(() => {
        const fetchBase64Logo = async () => {
            try {
                const base64 = await urlToBase64(
                    "https://img.freepik.com/premium-vector/hair-salon-logo-design-crown-salon_290562-205.jpg"
                );
                setBase64Logo(base64);
            } catch (error) {
                console.error("Error converting image to base64:", error);
            }
        };

        fetchBase64Logo();
    }, []);

    return (
        <div className={styles.invoiceWrapper}>
            {/* Header */}
            <header className={styles.header}>
                <div className={styles.companyInfo}>
                    <h1>ReleCloud</h1>
                    <p>Safely storing the world’s data</p>
                    <p>4321 Maplewood Ave., Nashville, TN 12345</p>
                    <p>(123) 456-7890 | (123) 456-7891</p>
                    <p>relecloud@example.com</p>
                </div>
                <div className={styles.invoiceDetails}>
                    <h2>QUOTE</h2>
                    <p><strong>Invoice #:</strong> {invoiceDataMaster?.invoiceNumber}</p>
                    <p><strong>Date:</strong> {invoiceDataMaster?.invoiceDate}</p>
                </div>
            </header>

            {/* Customer Information */}
            <section className={styles.customerInfo}>
                <div>
                    <h3>To:</h3>
                    <p>Frey Munch</p>
                    <p>Caneiro Group</p>
                    <p>89 Pacific Ave.</p>
                    <p>San Francisco, CA 54321</p>
                    <p>(123) 789-0123</p>
                </div>
                <div>
                    <p><strong>Customer ID No.:</strong> 0549</p>
                    <p><strong>Expiration Date:</strong> 2/9/23</p>
                </div>
            </section>

            {/* Salesperson and Job Info */}
            <section className={styles.jobInfo}>
                <table>
                    <thead>
                        <tr>
                            <th>Salesperson</th>
                            <th>Job</th>
                            <th>Payment Terms</th>
                            <th>Due Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cristina Echevarría</td>
                            <td>Sales Rep</td>
                            <td>Due on Receipt</td>
                            <td>TBD</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            {/* Line Items */}
            <section className={styles.lineItems}>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Unit Price</th>
                            <th>Qty.</th>
                            <th>Line Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>1 TB cloud storage</td>
                            <td>$9.99</td>
                            <td>$9.99</td>
                            <td>$9.99</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            {/* Summary */}
            <section className={styles.summary}>
                <div className={styles.totals}>
                    <p><strong>Subtotal:</strong> $9.99</p>
                    <p><strong>Sales Tax:</strong> $0.00</p>
                    <p><strong>Total:</strong> $9.99</p>
                </div>
            </section>

            {/* Footer */}
            <footer className={styles.footer}>
                <p>
                    This is a quotation on the goods named, subject to the conditions noted below:
                    All sales final, payment due upon receipt.
                </p>
                <p>Quotation prepared by: Cristina Echevarría</p>
                <p>
                    To accept this quotation, sign here and return: _______________________________
                </p>
                <p>Thank you for your business!</p>
            </footer>
        </div>
    );
};

export default ReleCloudTheme;
