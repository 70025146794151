import React, { useContext, useState } from 'react';
import styles from './CampaignLaunch.module.css';
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../../References/URLs';
import AuthContext from '../../../store/auth-context';
import ActiveStoreContext from '../../../store/ActiveStoreContext';

const CampaignLaunch = ({ data, setData }) => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null;
        } catch {
            return null;
        }
    })();
    const handleLaunch = () => {
        console.log('Launching Campaign:', data);
        setData({ ...data, status: 'launched' });
    };
    const [templateName, setTemplateName] = useState('free_haircut_ladies');
    const [audienceData, setAudienceData] = useState([
        {
            "phoneNumber": "9999648406",
            "clientStoreRelationIdentifier": "201655614158001803WALKIN167878453820230318",
            "storeIdentifier": "201655614158001803",
            "client_name": "Nav",
            "gender": "Female"
        },
        {
            "phoneNumber": "9319408406",
            "clientStoreRelationIdentifier": "201655614158001803WALKIN167878453820230317",
            "storeIdentifier": "201655614158001803",
            "client_name": "neet",
            "gender": "Female"
        },
        {
            "phoneNumber": "8448191903",
            "clientStoreRelationIdentifier": "201655614158001803WALKIN167878453820230316",
            "storeIdentifier": "201655614158001803",
            "client_name": "sapna",
            "gender": "Female"
        },
        {
            "phoneNumber": "8077348223",
            "clientStoreRelationIdentifier": "201655614158001803WALKIN167878453820230315",
            "storeIdentifier": "201655614158001803",
            "client_name": "neeraj",
            "gender": "Female"
        },
        {
            "phoneNumber": "8958120679",
            "clientStoreRelationIdentifier": "201655614158001803WALKIN167878453820230314",
            "storeIdentifier": "201655614158001803",
            "client_name": "Sourabh",
            "gender": "Female"
        }
    ]);
    const [analysisData, setAnalysisData] = useState({});

    // Helper function to divide data into batches of a specified size
    const chunkArray = (arr, size) => {
        const result = [];
        for (let i = 0; i < arr.length; i += size) {
            result.push(arr.slice(i, i + size));
        }
        return result;
    };

    // Function to send messages in batches
    const sendBatch = async (batch, template) => {
        const startTime = Date.now(); // Start time for this batch
        const response = await fetch(BASE_URL_API + "sendPromoComViaWaBulk", {
            method: "POST",
            body: JSON.stringify({
                token: authCtx.token,
                phoneNumberData: batch,
                templateName: template
            })
        });

        const endTime = Date.now(); // End time for this batch
        const executionTime = endTime - startTime; // Calculate time taken for this batch

        if (!response.ok) {
            console.error("Server error in batch request.");
            return {
                batchData: batch.map(item => ({ ...item, status: "Failed" })),
                executionTime,
                serverErrorCount: 1,
            };
        } else {
            const data = await response.json();
            const batchData = data?.response.map((item, idx) => ({
                ...batch[idx],
                status: item.status === "success" ? "Sent" : "Failed",
                responseData: item.response || {}
            }));

            const successfullySent = batchData.filter(item => item.status === "Sent").length;
            const failedSent = batchData.filter(item => item.status === "Failed").length;

            return {
                batchData,
                executionTime,
                successfullySent,
                failedSent,
                serverErrorCount: 0,
            };
        }
    };

    // Function to analyze and log batch processing statistics
    const analyzeBatchProcess = (batches, batchResults, startTime) => {
        const totalExecutionTime = Date.now() - startTime;
        const totalBatches = batches.length;
        const totalData = audienceData.length;

        const successfullySent = batchResults.reduce((acc, res) => acc + res.successfullySent, 0);
        const failedSent = batchResults.reduce((acc, res) => acc + res.failedSent, 0);
        const serverErrorCount = batchResults.reduce((acc, res) => acc + res.serverErrorCount, 0);

        const averageBatchTime = batchResults.reduce((acc, res) => acc + res.executionTime, 0) / totalBatches;

        setAnalysisData({
            totalData,
            totalBatches,
            totalExecutionTime,
            successfullySent,
            failedSent,
            serverErrorCount,
            averageBatchTime,
        });

        console.log("Batch Analysis:", {
            totalData,
            totalBatches,
            totalExecutionTime,
            successfullySent,
            failedSent,
            serverErrorCount,
            averageBatchTime,
        });
    };

    // Function to handle the entire audience data in batches
    const sendAllPromoComViaWa = async () => {
        const startTime = Date.now();
        const batches = chunkArray(audienceData, 100);
        const batchResults = [];

        for (const batch of batches) {
            const result = await sendBatch(batch, templateName);
            batchResults.push(result);

            // Update the status in audienceData in real time
            setAudienceData(prevData =>
                prevData.map(item =>
                    result.batchData.find(res => res.phoneNumber === item.phoneNumber) || item
                )
            );
        }

        // Analyze the batch process once all batches are completed
        analyzeBatchProcess(batches, batchResults, startTime);
    };

    console.log(analysisData);
    // console.log(audienceData);

    return (
        <div className={styles.container}>
            <div>Launch Campaign</div>
            <div>Are you ready to launch your campaign?</div>
            <button onClick={handleLaunch} className={styles.launchBtn}>
                Launch
            </button>
            <Link to={`/communications/send`} >
                Launch Now
            </Link>


            <div>
                <button onClick={() => sendAllPromoComViaWa()}>Send OTP to All in Batches</button>

                <div>
                    {/* <button onClick={() => getPhoneNumberToSend()}>Get Audience</button> */}
                    <div>
                        <h3>All users</h3>
                        <div className={styles.listWrapper}>
                            {audienceData.map((item, index) => (
                                <div className={styles.eachRow} key={index}>
                                    <div>{index + 1}.</div>
                                    <div className={styles.client_name}>{item.client_name}</div>
                                    <div className={styles.phoneNumber}>{item.phoneNumber}</div>
                                    <div>{item.gender}</div>
                                    <div>{item.status || "Pending"}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h3>Analysis Data</h3>
                        <pre>{JSON.stringify(analysisData, null, 2)}</pre>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignLaunch;
