import React from "react";
import styles from "./SleekInvoiceTheme.module.css";

const SleekInvoiceTheme = ({
    selectedPalette,
    invoiceDataMaster,
    themeData,
}) => {
    const { meta } = themeData;
    const {
        clientData,
        productData,
        totalAmount,
        invoiceDate,
        dueDate,
        customFields,
        tncData,
    } = invoiceDataMaster;

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: selectedPalette.backgroundColor ?? meta.backgroundColor,
                color: selectedPalette.textColor ?? meta.textColor,
                fontFamily: meta.fontFamily,
            }}
            id="invoicePreview"
        >
            {/* Header Section */}
            <header className={styles.header}>
                <div className={styles.companyDetails}>
                    <h1>{meta.companyName}</h1>
                    <p>{meta.companyAddress}</p>
                    <p>{meta.companyContact}</p>
                    <p>{meta.companyEmail}</p>
                </div>
                <div className={styles.invoiceDetails}>
                    <h2>INVOICE</h2>
                    <p>
                        <strong>Date:</strong> {invoiceDate}
                    </p>
                    <p>
                        <strong>Invoice #:</strong> {invoiceDataMaster.sessionIdentifier.slice(-6)}
                    </p>
                    <p>
                        <strong>Bill To:</strong> {clientData.client_name}
                    </p>
                </div>
            </header>

            {/* Product Table Section */}
            <section>
                <table className={styles.productTable}>
                    <thead style={{
                        backgroundColor: selectedPalette.tableHeaderColor,
                        color: selectedPalette.tableHeaderTextColor,
                    }}>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody style={{
                        backgroundColor: selectedPalette.tableBodyBackgroundColor,
                        color: selectedPalette.tableBodyTextColor,
                    }}>
                        {productData.map((product, index) => (
                            <tr key={index}>
                                <td>{product.productTitle}</td>
                                <td>${product.total}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            {/* Summary Section */}
            <section className={styles.summarySection}>
                <div className={styles.summaryDetails}>
                    <p>
                        <strong>Subtotal:</strong>
                    </p>
                    <p>
                        <strong>Tax Rate:</strong>
                    </p>
                    <p>
                        <strong>Total:</strong>
                    </p>
                </div>
                <div className={styles.summaryValues}>
                    <p>${totalAmount}</p>
                    <p>{meta.taxRate ?? "0%"}</p>
                    <p>${totalAmount}</p>
                </div>
            </section>

            {/* Footer Section */}
            <footer className={styles.footer}>
                <p>{customFields[0]?.value}</p>
                <p>{tncData[0]?.detail}</p>
            </footer>
        </div>
    );
};

export default SleekInvoiceTheme;
