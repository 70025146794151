import React, { useContext, useEffect, useState } from 'react'
import styles from '../Membership/BuyCardForm.module.css'
import OtpInput from 'react-otp-input';
import AddNewClient from '../../Components/Users/AddNewClient';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import { ShimmerButton, ShimmerText, ShimmerTitle } from "react-shimmer-effects";
import { useNavigate } from 'react-router';
import ActiveStoreContext from '../../store/ActiveStoreContext';


const BuyCardForm = props => {
    const item = props.item;
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    let navigate = useNavigate()
    const [isUserFormOpen, setIsUserFormOpen] = useState(false)
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const [cardNumber, setCardNumber] = useState({
        otp: props.item.identifierDigits
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [gender, setGender] = useState(''); //MALE, FEMALE, LGBTQ, UNKNOWN
    const [userDetails, setUserDetails] = useState(null);
    const cardNumberHandler = (otp) => {
        setCardNumber({ otp })
    }
    const [phoneNumber, setPhoneNumber] = useState({
        otp: ''
    })


    const [gstAdded, setGstAdded] = useState(true)
    const [gt, setGt] = useState(0)
    const [payMode, setPayMode] = useState('')
    const [responseMsg, setResponseMsg] = useState('');

    const phoneNumberHandler = (otp) => {
        setPhoneNumber({ otp });
        if (otp.length < 10) {
            setUserDetailsFetched(false)
        }
    }

    const userDetailsFetchHandler = () => {
        getClientDetails()
        // setUserDetailsFetched(!userDetailsFetched)
        // setIsUserFormOpen(true)
    }


    const closeModal = () => {
        setIsUserFormOpen(false);
    }

    const getClientDetails = async () => {
        setIsLoading(true)
        const getClientDetailsResponse = await fetch(BASE_URL_API + "getClientDetails",
            {
                method: 'POST',
                body: JSON.stringify({
                    storeIdentifier: activeStore?.storeIdentifier,
                    phoneNumber: phoneNumber.otp,
                    token: authCtx.token
                })
            });

        if (!getClientDetailsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getClientDetailsRespo = await getClientDetailsResponse.json();
            if (getClientDetailsRespo.status === "success") {
                if (getClientDetailsRespo.message === "user already registered!") {
                    setUserDetailsFetched(true)
                    setUserDetails(getClientDetailsRespo.response)
                } else {
                    setIsUserFormOpen(true)
                }
            } else {
                if (getClientDetailsRespo.message === 'tokenExpired') {
                    authCtx.logout();
                } else {
                    console.log(getClientDetailsRespo);
                }
                setUserDetails(null);
            }
            setIsLoading(false)
        }

    }

    const amountCalHandler = () => {

    }

    useEffect(() => {
        if (gstAdded) {
            setGt(parseFloat(parseFloat(item.toPay) * 1.18).toFixed(0))
        } else {
            setGt(item.toPay)
        }
    }, [item, gstAdded])

    const purchaseCard = async () => {
        setIsCreating(true)
        const purchaseCardResponse = await fetch(BASE_URL_API + "purchaseCard",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cardNumber: cardNumber.otp,
                    storeIdentifier : activeStore.storeIdentifier,
                    cardIdentifier: item.cardIdentifier,
                    amountPaid: gt,
                    clientName: userDetails.client_name,
                    clientStoreRelationIdentifier: userDetails.clientStoreRelationIdentifier,
                    mobile: userDetails.mobile,
                    hasGstPaid: gstAdded,
                    cardWorth: item.worthOff,
                    payMode: payMode,
                    duration : item?.duration,
                    globalCardIdentifier : item?.globalCardIdentifier
                })
            });

        if (!purchaseCardResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const purchaseCardRespo = await purchaseCardResponse.json();
            if (purchaseCardRespo.status === "success") {
                navigate(`/memberships`);
            } else {
                if (purchaseCardRespo.message === "tokenExpired") {
                    authCtx.logout();
                } else {
                    setResponseMsg(purchaseCardRespo.message);
                }
            }
            console.log(purchaseCardRespo);
        }
        setIsCreating(false)
    }



    // console.log(userDetails)

    return (
        <div className={styles.mainWrapper}
            style={{ animation: `0.2s cubic-bezier(0, 1.19, 0.81, 0.98) 0s 1 normal forwards running showBuyForm` }}
        // style={{ animation : `0.5s forwards showBuyForm` }}
        >
            <AddNewClient
                isOpen={isUserFormOpen}
                closeModal={closeModal}
                phone={phoneNumber.otp}
            />
            <div className={styles.mainContainer}>

                <div className={styles.mainContent}>
                    <div className={styles.responseMessageSection}>
                        <div>
                            {responseMsg}
                        </div>
                    </div>

                    <div className={styles.eachInputSection}>
                        <div className={styles.eachInputTitle}>
                            Please enter card number
                        </div>
                        <OtpInput
                            value={cardNumber.otp}
                            numInputs={16}
                            shouldAutoFocus={true}
                            onChange={(otp) => cardNumberHandler(otp)}
                            isInputNum={true}
                            containerStyle={styles.cardNumberContainer}
                        // placeholder="Please enter card number"
                        />

                    </div>
                    <div className={styles.userDetailsSection}>
                        <div className={styles.eachInputSection}>
                            <div className={styles.eachInputTitle}>
                                Please enter customer's phone number
                            </div>
                            <div className={styles.phoneWrapper}>
                                <OtpInput
                                    value={phoneNumber.otp}
                                    numInputs={10}
                                    shouldAutoFocus={false}
                                    onChange={(otp) => phoneNumberHandler(otp)}
                                    isInputNum={true}
                                    containerStyle={styles.cardNumberContainer}
                                />
                                {phoneNumber.otp.length === 10 &&
                                    <div className={styles.getDetailsBtnWrapper}>
                                        {isLoading === false ?
                                            <button onClick={() => userDetailsFetchHandler()} className={styles.getDetailsBtn} >Get Details</button>
                                            :
                                            <ShimmerButton size="sm" className={styles.shBtn} />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {userDetailsFetched === true &&
                            <>

                                <div className={styles.eachInputSection}>
                                    <div className={styles.eachInputTitle}>
                                        {userDetails.client_name}
                                    </div>
                                </div>
                                {/* <div className={styles.genderRow}>
                                    <div className={styles.genderTitle}>
                                        Gender
                                    </div>
                                    <div className={styles.genderOptions}>
                                        <button onClick={() => setGender('MALE')}
                                            className={gender==='MALE' ? styles.genderBtnSelected : styles.genderBtn}
                                        >
                                            Male
                                        </button>
                                        <button onClick={() => setGender('FEMALE')}
                                            className={gender==='FEMALE' ? styles.genderBtnSelected : styles.genderBtn}
                                        >
                                            Female
                                        </button>
                                        <button onClick={() => setGender('LGBTQ')}
                                            className={gender==='LGBTQ' ? styles.genderBtnSelected : styles.genderBtn}
                                        >
                                            LGBTQ
                                        </button>
                                        <button onClick={() => setGender('UNKNOWN')}
                                            className={gender==='UNKNOWN' ? styles.genderBtnSelected : styles.genderBtn}
                                        >
                                            Unknown
                                        </button>
                                    </div>
                                </div> */}
                            </>
                        }
                    </div>

                    {userDetailsFetched === true &&
                        <div className={styles.collectPaymentSection}>
                            <div className={styles.sectionTitle}>
                                Collect Payment
                            </div>
                            <div>
                                <div className={styles.amountRow}>
                                    <div>
                                        Amount
                                    </div>
                                    <div>
                                        &#8377;{item.toPay}/-
                                    </div>
                                </div>
                                <div className={styles.gstRow}>
                                    <div className={styles.gstCaptionWrapper}>
                                        <div>GST(18%)</div>
                                        <div className={styles.gstToggleWrapper}>
                                            {gstAdded === true ?
                                                <button className={styles.gstRemove}
                                                    onClick={() => setGstAdded(!gstAdded)}
                                                >Remove</button>
                                                :
                                                <button className={styles.gstAdd}
                                                    onClick={() => setGstAdded(!gstAdded)}
                                                >Add</button>
                                            }
                                        </div>
                                    </div>
                                    <div>+&#8377;{parseFloat(item.toPay * 0.18).toFixed(0)}/-</div>
                                </div>
                                <div className={styles.gtRow}>
                                    <div>Grand Total</div>
                                    <div>&#8377;{gt}/-</div>
                                </div>
                            </div>
                            <div className={styles.pmRow}>
                                <div className={styles.pmTitle}>
                                    Payment mode
                                </div>
                                <div className={styles.pmBtns}>
                                    <button className={payMode === 'Cash' ? styles.payModeBtnSelected : styles.payModeBtn}
                                        onClick={() => setPayMode('Cash')}
                                    >
                                        Cash
                                    </button>
                                    <button className={payMode === 'Card' ? styles.payModeBtnSelected : styles.payModeBtn}
                                        onClick={() => setPayMode('Card')}
                                    >
                                        Card
                                    </button>
                                    <button className={payMode === 'Qr' ? styles.payModeBtnSelected : styles.payModeBtn}
                                        onClick={() => setPayMode('Qr')}
                                    >
                                        Qr
                                    </button>
                                    <button className={payMode === 'Link' ? styles.payModeBtnSelected : styles.payModeBtn}
                                        onClick={() => setPayMode('Link')}
                                    >
                                        Link
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {userDetailsFetched === true &&
                        <div className={styles.submitBtnSection}>
                            {isCreating === true ?
                                <ShimmerButton className={styles.shimmerBtn} size="md" />
                                :
                                <button onClick={() => purchaseCard()} >Submit</button>
                            }
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default BuyCardForm