import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaStar, FaCheckCircle, FaFilter } from 'react-icons/fa';
import styles from '../Feedback/ReviewsHolder.module.css';
import EachFeedbackBox from './EachFeedbackBox';

const ReviewsHolder = props => {
    const { isLoading, feedbackData } = props;

    const [selectedStar, setSelectedStar] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isSalonEnvironment, setIsSalonEnvironment] = useState(false);
    const [isServiceResult, setIsServiceResult] = useState(false);
    const [isStaffBehavior, setIsStaffBehavior] = useState(false);
    const [selectedInterval, setSelectedInterval] = useState('');

    const filtersHandler = () => {
        let temp = feedbackData;
        if (selectedStar !== '') {
            temp = temp.filter((item) => item.selectedStar === selectedStar);
        }

        if (isSalonEnvironment) {
            temp = temp.filter((item) => item.starSelectedSalonEnvironment !== '0');
        }

        if (isServiceResult) {
            temp = temp.filter((item) => item.starSelectedServiceResults !== '0');
        }

        if (isStaffBehavior) {
            temp = temp.filter((item) => item.starSelectedStaffBehaviour !== '0');
        }

        temp = temp.filter(review => {
            const count = temp.filter(r => r.clientId === review.clientId).length;
            switch (selectedInterval) {
                case 'Clients with 1 review':
                    return count === 1;
                case 'Clients with more than 1 review':
                    return count > 1;
                case 'Clients with more than 2 reviews':
                    return count > 2;
                case 'Clients with 5 reviews':
                    return count === 5;
                case 'Clients with more than 5 reviews':
                    return count > 5;
                default:
                    return true;
            }
        });

        setFilteredData(temp);
    };

    useEffect(() => {
        filtersHandler();
    }, [selectedStar, isSalonEnvironment, isServiceResult, isStaffBehavior, feedbackData, selectedInterval]);

    const intervals = [
        'Clients with 1 review',
        'Clients with more than 1 review',
        'Clients with more than 2 reviews',
        'Clients with 5 reviews',
        'Clients with more than 5 reviews',
    ];

    return (
        <motion.div className={styles.reviewsHolderWrapper} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            <motion.div className={styles.rwHeader} initial={{ y: -50 }} animate={{ y: 0 }} transition={{ duration: 0.6 }}>
                <div className={styles.selectWrapper}>
                    <select onChange={(e) => setSelectedStar(e.target.value)} value={selectedStar} className={styles.selectBox}>
                        <option value="">All Stars</option>
                        <option value="5">5</option>
                        <option value="4">4</option>
                        <option value="3">3</option>
                        <option value="2">2</option>
                        <option value="1">1</option>
                    </select>
                </div>

                <motion.div className={styles.optionWrapper} initial={{ scale: 0.8 }} animate={{ scale: 1 }} transition={{ duration: 0.6 }}>
                    <button onClick={() => setIsSalonEnvironment(!isSalonEnvironment)}
                        className={isSalonEnvironment === false ? styles.defaultBtn : styles.selectedBtn}>
                        <FaCheckCircle /> Salon Environment
                    </button>
                    <button onClick={() => setIsServiceResult(!isServiceResult)}
                        className={isServiceResult === false ? styles.defaultBtn : styles.selectedBtn}>
                        <FaCheckCircle /> Service Results
                    </button>
                    <button onClick={() => setIsStaffBehavior(!isStaffBehavior)}
                        className={isStaffBehavior === false ? styles.defaultBtn : styles.selectedBtn}>
                        <FaCheckCircle /> Staff Behaviour
                    </button>
                </motion.div>

                <div className={styles.selectWrapper}>
                    <select value={selectedInterval} onChange={e => setSelectedInterval(e.target.value)} className={styles.selectBox}>
                        <option value="">All reviews</option>
                        {intervals.map(interval => (
                            <option key={interval} value={interval}>{interval}</option>
                        ))}
                    </select>
                </div>

                <motion.div className={styles.reviewCount} whileHover={{ scale: 1.1 }}>
                    <FaFilter /> {filteredData.length} Reviews
                </motion.div>
            </motion.div>

            <motion.div className={styles.rwBody} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }}>
                {isLoading === false ? (
                    <div className={styles.reviewList}>
                        {filteredData?.map((item, index) => (
                            <EachFeedbackBox key={index} item={item} />
                        ))}
                    </div>
                ) : (
                    <div className={styles.loadingWrapper}>Loading...</div>
                )}
            </motion.div>
        </motion.div>
    );
};

export default ReviewsHolder;