import React from 'react';
import { useNavigate } from 'react-router';
import styles from './ConfigMembershipCardsHeader.module.css';

const ConfigMembershipCardsHeader = ({ selectedCard }) => {
    const navigate = useNavigate();

    const selectCardHandler = (val) => {
        if (!val) return;
        navigate(`/config/membership-card/${val}`);
    };

    return (
        <div className={styles.mainHeader}>
            <div className={styles.selectACard}>
                <select
                    onChange={(e) => selectCardHandler(e.target.value)}
                    value={selectedCard}
                >
                    <option value="select-a-card">Select A Card</option>
                    <option value="card-A">Card A</option>
                    <option value="create-a-new-card">Create A New Card</option>
                </select>
            </div>
        </div>
    );
};

export default ConfigMembershipCardsHeader;
