import React, { useEffect, useState } from 'react';
import ConfigMembershipCNCStoreAndServicesWrapper from './ConfigMembershipCNCStoreAndServicesWrapper';
import styles from './ConfigMembershipCreateNewCard.module.css';

const ConfigMembershipCreateNewCard = () => {
    const [activeTab, setActiveTab] = useState('BD');

    const [cardType, setCardType] = useState('');
    const [isShareable, setIsShareable] = useState(false);
    const [shareLimit, setShareLimit] = useState(1);
    const [redeemableStores, setRedeemableStores] = useState([]);
    const [expiryDate, setExpiryDate] = useState('');
    const [termsAndConditions, setTermsAndConditions] = useState('');
    const [cardPreview, setCardPreview] = useState(null);
    const [formValid, setFormValid] = useState(false);

    // Validate form whenever inputs change
    useEffect(() => {
        setFormValid(cardType && expiryDate && (!isShareable || (isShareable && shareLimit > 0)));
    }, [cardType, expiryDate, isShareable, shareLimit]);

    const handlePreview = () => {
        setCardPreview({
            cardType,
            isShareable,
            shareLimit,
            redeemableStores,
            expiryDate,
            termsAndConditions,
        });
    };

    const resetForm = () => {
        setCardType('');
        setIsShareable(false);
        setShareLimit(1);
        setRedeemableStores([]);
        setExpiryDate('');
        setTermsAndConditions('');
        setCardPreview(null);
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'BD':
                return (
                    <div className={styles.mainContent}>
                        <select>
                            <option>Select Type of Card</option>
                            <option>Truly Unlimited</option>
                            <option>Value Addition</option>
                        </select>


                        <h2>Create a New Membership Card</h2>
                        <div className={styles.formSection}>
                            {/* Card Type Selection */}
                            <div className={styles.formGroup}>
                                <label>Type of Card</label>
                                <select
                                    value={cardType}
                                    onChange={(e) => setCardType(e.target.value)}
                                >
                                    <option value="">Select Card Type</option>
                                    <option value="truly-unlimited">Truly Unlimited</option>
                                    <option value="value-addition">Value Addition</option>
                                    <option value="custom">Custom</option>
                                </select>
                            </div>

                            {/* Shareability Configuration */}
                            <div className={styles.formGroup}>
                                <label>
                                    Is this card shareable?
                                    <input
                                        type="checkbox"
                                        checked={isShareable}
                                        onChange={(e) => setIsShareable(e.target.checked)}
                                    />
                                </label>
                                {isShareable && (
                                    <div>
                                        <label>Max Shares</label>
                                        <input
                                            type="number"
                                            value={shareLimit}
                                            onChange={(e) => setShareLimit(Number(e.target.value))}
                                            min="1"
                                        />
                                    </div>
                                )}
                            </div>

                            {/* Redeemable Stores */}
                            <div className={styles.formGroup}>
                                <label>Redeemable at Stores</label>
                                <input
                                    type="text"
                                    placeholder="Enter store names (comma-separated)"
                                    onChange={(e) =>
                                        setRedeemableStores(e.target.value.split(',').map((store) => store.trim()))
                                    }
                                />
                            </div>

                            {/* Expiry Date */}
                            <div className={styles.formGroup}>
                                <label>Expiry Date</label>
                                <input
                                    type="date"
                                    value={expiryDate}
                                    onChange={(e) => setExpiryDate(e.target.value)}
                                />
                            </div>

                            {/* Terms and Conditions */}
                            <div className={styles.formGroup}>
                                <label>Terms and Conditions</label>
                                <textarea
                                    rows="5"
                                    value={termsAndConditions}
                                    onChange={(e) => setTermsAndConditions(e.target.value)}
                                    placeholder="Write terms and conditions here"
                                />
                            </div>

                            {/* Preview and Confirm */}
                            <div className={styles.actions}>
                                <button onClick={handlePreview} disabled={!formValid}>Preview</button>
                                <button onClick={resetForm}>Reset</button>
                                <button disabled={!formValid}>Save Card</button>
                            </div>
                        </div>

                        {/* Preview Section */}
                        {cardPreview && (
                            <div className={styles.previewSection}>
                                <h3>Card Preview</h3>
                                <p><strong>Type:</strong> {cardPreview.cardType}</p>
                                <p>
                                    <strong>Shareable:</strong>{' '}
                                    {cardPreview.isShareable
                                        ? `Yes (Max ${cardPreview.shareLimit} shares)`
                                        : 'No'}
                                </p>
                                <p>
                                    <strong>Redeemable at:</strong>{' '}
                                    {cardPreview.redeemableStores.join(', ') || 'None'}
                                </p>
                                <p><strong>Expiry Date:</strong> {cardPreview.expiryDate}</p>
                                <p>
                                    <strong>Terms and Conditions:</strong>{' '}
                                    {cardPreview.termsAndConditions || 'None'}
                                </p>
                            </div>
                        )}
                    </div>
                );
            case 'StoresAndServices':
                return <ConfigMembershipCNCStoreAndServicesWrapper />;
            case 'PriceAndValidity':
                return <div className={styles.placeholder}>Price & Validity</div>;
            case 'TnCs':
                return <div className={styles.placeholder}>Terms of Use</div>;
            case 'Confirm':
                return <div className={styles.placeholder}>Confirm Your Card Details</div>;
            default:
                return <div className={styles.error}>Invalid Tab Selected</div>;
        }
    };

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainLoaderWrapper}>
                <div className={styles.mainLoaderContent}>
                    <div className={styles.contentBox}>
                        <div className={styles.awesomeTitle}>Awesome,</div>
                        <div className={styles.awesomeSubTitle}>
                            Let's create some smart cards..
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.mainContentWrapper}>
                <div className={styles.mainContentHeader}>
                    <div className={styles.mainContentHeaderContainer}>
                        {['BD', 'StoresAndServices', 'PriceAndValidity', 'TnCs', 'Confirm'].map(
                            (tab) => (
                                <button
                                    key={tab}
                                    className={
                                        activeTab === tab ? styles.activeTabButton : styles.tabButton
                                    }
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab.replace(/([A-Z])/g, ' $1')}
                                </button>
                            )
                        )}
                    </div>
                </div>

                <div>
                    <div className={styles.mainContent}>
                        <select>
                            <option>Select Type of Card</option>
                            <option>Truly Unlimited</option>
                            <option>Value Addition</option>
                        </select>

                        <div className={styles.mainContainer} >
                            <div className={styles.formSection}>
                                {/* Card Type Selection */}
                                <div className={styles.formGroup}>
                                    <label>Type of Card</label>
                                    <select
                                        value={cardType}
                                        onChange={(e) => setCardType(e.target.value)}
                                    >
                                        <option value="">Select Card Type</option>
                                        <option value="truly-unlimited">Truly Unlimited</option>
                                        <option value="value-addition">Value Addition</option>
                                        <option value="custom">Custom</option>
                                    </select>
                                </div>

                                {/* Shareability Configuration */}
                                <div className={styles.formGroup}>
                                    <label>
                                        Is this card shareable?
                                        <input
                                            type="checkbox"
                                            checked={isShareable}
                                            onChange={(e) => setIsShareable(e.target.checked)}
                                        />
                                    </label>
                                    {isShareable && (
                                        <div>
                                            <label>Max Shares</label>
                                            <input
                                                type="number"
                                                value={shareLimit}
                                                onChange={(e) => setShareLimit(Number(e.target.value))}
                                                min="1"
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Redeemable Stores */}
                                <div className={styles.formGroup}>
                                    <label>Redeemable at Stores</label>
                                    <input
                                        type="text"
                                        placeholder="Enter store names (comma-separated)"
                                        onChange={(e) =>
                                            setRedeemableStores(e.target.value.split(',').map((store) => store.trim()))
                                        }
                                    />
                                </div>

                                <ConfigMembershipCNCStoreAndServicesWrapper />
                                {/* Expiry Date */}
                                <div className={styles.formGroup}>
                                    <label>Expiry Date</label>
                                    <input
                                        type="date"
                                        value={expiryDate}
                                        onChange={(e) => setExpiryDate(e.target.value)}
                                    />
                                </div>

                                {/* Terms and Conditions */}
                                <div className={styles.formGroup}>
                                    <label>Terms and Conditions</label>
                                    <textarea
                                        rows="5"
                                        value={termsAndConditions}
                                        onChange={(e) => setTermsAndConditions(e.target.value)}
                                        placeholder="Write terms and conditions here"
                                    />
                                </div>

                                {/* Preview and Confirm */}
                                <div className={styles.actions}>
                                    <button onClick={handlePreview} disabled={!formValid}>Preview</button>
                                    <button onClick={resetForm}>Reset</button>
                                    <button disabled={!formValid}>Save Card</button>
                                </div>
                            </div>

                            {/* Preview Section */}
                            {cardPreview && (
                                <div className={styles.previewSection}>
                                    <h3>Card Preview</h3>
                                    <p><strong>Type:</strong> {cardPreview.cardType}</p>
                                    <p>
                                        <strong>Shareable:</strong>{' '}
                                        {cardPreview.isShareable
                                            ? `Yes (Max ${cardPreview.shareLimit} shares)`
                                            : 'No'}
                                    </p>
                                    <p>
                                        <strong>Redeemable at:</strong>{' '}
                                        {cardPreview.redeemableStores.join(', ') || 'None'}
                                    </p>
                                    <p><strong>Expiry Date:</strong> {cardPreview.expiryDate}</p>
                                    <p>
                                        <strong>Terms and Conditions:</strong>{' '}
                                        {cardPreview.termsAndConditions || 'None'}
                                    </p>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfigMembershipCreateNewCard;


