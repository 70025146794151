import React, { useState } from 'react';
import styles from './CampaignScheduler.module.css';

const CampaignScheduler = ({ data, setData }) => {
    const [schedule, setSchedule] = useState(data.schedule);

    const handleScheduleChange = (e) => {
        setSchedule(e.target.value);
        setData({ ...data, schedule: e.target.value });
    };

    return (
        <div className={styles.container}>
            <h2>Schedule Campaign</h2>
            <input
                type="datetime-local"
                value={schedule}
                onChange={handleScheduleChange}
                className={styles.input}
            />
            <button className={styles.nextBtn}>Next</button>
        </div>
    );
};

export default CampaignScheduler;
