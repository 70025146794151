import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styles from './SaveAudience.module.css';
import { MdClose } from 'react-icons/md';
import { BASE_URL_API } from '../../../References/URLs';
import AuthContext from '../../../store/auth-context';
import ActiveStoreContext from '../../../store/ActiveStoreContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router'

const SaveAudience = ({ isOpen, data, onClose }) => {
    const [audienceName, setAudienceName] = useState('')
    const [audienceDescription, setAudienceDescription] = useState('')
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isSavingAudience, setIsSavingAudience] = useState(false)
    const nav = useNavigate()
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEscape);
        return () => window.removeEventListener('keydown', handleEscape);
    }, [onClose]);

    const handleOutsideClick = (e) => {
        if (e.target.id === 'overlay') {
            onClose();
        }
    }

    const saveAudienceHandler = async () => {
        if (audienceName !== '') {
            if (audienceDescription !== '') {
                setIsSavingAudience(true)
                const createAudienceResponse = await fetch(BASE_URL_API + "createAudience",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token: authCtx.token,
                            storeIdentifier: activeStore.storeIdentifier,
                            audienceName: audienceName,
                            audienceDescription: audienceDescription,
                            audienceData: data
                        }),
                    });

                if (!createAudienceResponse.ok) {
                    console.log("Something went wrong : Server Error");
                } else {
                    const createAudienceRespo = await createAudienceResponse.json();
                    if (createAudienceRespo.status === "success") {
                        toast(`Audience successfully created!`)
                        nav(`/communications/audiences`)
                    } else if (createAudienceRespo?.message === "tokenExpired") {
                        authCtx.logout();
                    } else {

                    }
                    console.log(createAudienceRespo);
                }
                setIsSavingAudience(false)
            } else {
                alert(`Please enter audience description..`)
            }
        } else {
            alert(`Please enter audience name..`)
        }
    }

    return (
        <>
            {isOpen && (
                <div
                    id="overlay"
                    className={styles.overlay}
                    onClick={handleOutsideClick}
                >
                    <motion.div
                        className={styles.sidebar}
                        initial={{ x: '100%' }}
                        animate={{ x: '0%' }}
                        exit={{ x: '100%' }}
                        transition={{ duration: 0.4 }}
                    >
                        <div className={styles.mainTitleWrapper} >
                            <div className={styles.headerWrapper} >
                                <button className={styles.closeButton} onClick={onClose}>
                                    <MdClose color={"white"} />
                                </button>
                                <div className={styles.title}>Save Audience</div>
                                {isSavingAudience === true ?
                                    <button className={styles.saveButton} disabled >Saving...</button>
                                    :
                                    <button className={styles.saveButton} onClick={() => saveAudienceHandler()} >Save</button>
                                }
                            </div>
                            <div className={styles.form}>
                                <input type="text" className={styles.input} autoFocus placeholder="Enter audience name"
                                    onChange={(e) => setAudienceName(e.target.value)}
                                />
                                <textarea className={styles.textarea}
                                    placeholder="Enter description"
                                    onChange={(e) => setAudienceDescription(e.target.value)}
                                >
                                </textarea>
                            </div>
                        </div>
                        <div className={styles.tableWrapper}>
                            <div className={styles.tableContainer}>
                                {data.map((item, index) => (
                                    <motion.div
                                        className={styles.row}
                                        key={index}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <div className={styles.cell}>{item.client_name}</div>
                                        <div className={styles.cell}>{item.mobile}</div>
                                        <div className={styles.cell}>{item.gender}</div>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                </div>
            )}
        </>
    );
};

export default SaveAudience;
