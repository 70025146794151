import React from 'react';
import { useParams, useNavigate } from 'react-router';
import ConfigMembershipCardsHeader from '../../Components/Membership/ConfigMembershipCardsHeader';
import ConfigMembershipCreateNewCard from './ConfigMembershipCreateNewCard';
import styles from './ConfigMembershipCards.module.css';

const ConfigMembershipCards = () => {
    const params = useParams();
    const selectedCard = params?.selectedCard || 'select-a-card';

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <ConfigMembershipCardsHeader selectedCard={selectedCard} />
                {selectedCard === 'create-a-new-card' && <ConfigMembershipCreateNewCard />}
            </div>
        </div>
    );
};

export default ConfigMembershipCards;
