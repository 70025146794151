import React, { useState, useRef, useEffect } from "react";
import styles from './SearchableDropdown.module.css';

const SearchableDropdown = ({ placeholder, data, productTitle, filterKey, onItemSelect }) => {
    const [query, setQuery] = useState();
    const [isFocused, setIsFocused] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const inputRef = useRef(null);

    // Add "Add New Service / Item" as the first suggestion
    const filteredData = [
        { [filterKey]: "Add New Service / Item" },
        ...data.filter(
            (item) =>
                item[filterKey] &&
                item[filterKey]?.toLowerCase()?.includes(query?.toLowerCase())
        ),
    ];
    useEffect(() => {
        setQuery(productTitle)
    }, [productTitle])
    

    const handleKeyDown = (e) => {
        if (!isFocused) return;

        switch (e.key) {
            case "ArrowDown":
                setHighlightedIndex((prev) =>
                    prev < filteredData.length - 1 ? prev + 1 : 0
                );
                break;
            case "ArrowUp":
                setHighlightedIndex((prev) =>
                    prev > 0 ? prev - 1 : filteredData.length - 1
                );
                break;
            case "Enter":
                e.preventDefault();
                if (highlightedIndex === 0) {
                    // If "Add New Service / Item" is selected, pass the query as new item
                    handleSelect({ [filterKey]: query || "Add New Service / Item" });
                } else if (
                    highlightedIndex > 0 &&
                    highlightedIndex < filteredData.length
                ) {
                    handleSelect(filteredData[highlightedIndex]);
                } else if (query.trim() !== "") {
                    // If query is not empty, pass the manually typed value as the selection
                    handleSelect({ [filterKey]: query });
                }
                break;
            case "Escape":
                setIsFocused(false);
                setQuery("");
                break;
            default:
                break;
        }
    };

    const handleSelect = (item) => {
        setQuery(item[filterKey]);
        setIsFocused(false);
        onItemSelect(item);
    };

    const handleBlur = () => {
        // Update the state to select the item if the user has typed and blurred
        if (query.trim() !== "") {
            onItemSelect({ [filterKey]: query });
        }
        setIsFocused(false);
    };


    return (
        <div
            className={styles.dropdownContainer}
            onBlur={handleBlur}
            onFocus={() => setIsFocused(true)}
            tabIndex={0}
        >
            <input
                type="text"
                ref={inputRef}
                placeholder={placeholder}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsFocused(true)}
                className={styles.searchInput}
                autoFocus
            />
            {isFocused && (
                <div className={styles.suggestionsList}>
                    {filteredData.map((item, index) => (
                        <div
                            key={index}
                            className={`${styles.suggestionItem} ${index === highlightedIndex ? styles.highlighted : ""
                                }`}
                            onMouseDown={() => handleSelect(item)} // Prevent onBlur from triggering
                        >
                            {item[filterKey]}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchableDropdown;
