import React, { useContext, useState } from "react";
import Papa from "papaparse";
import { FaTrashAlt } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { motion } from "framer-motion";
import styles from "./UploadClientDataInBulk.module.css";
import { v4 as uuidv4 } from "uuid";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import ActiveStoreContext from "../../store/ActiveStoreContext";
import { BASE_URL_API } from "../../References/URLs";
import AuthContext from "../../store/auth-context";

const UploadClientDataInBulk = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const hiddenKeys = ["id", "clientStoreRelationIdentifier"];


    const sampleData = [
        {
            id: uuidv4(),
            salutation: "Mr.",
            // storeIdentifier: "STR123456",
            email: "example@example.com",
            countryCode: "+91",
            phoneNumber: "9876543210",
            alternateNumber: "1234567890",
            whatsAppNumber: "1234567890",
            clientName: "John Doe",
            address: "123 Street, City",
            currentStatus: ''
        },
    ];

    // Download sample CSV file
    const downloadSample = () => {
        const csv = Papa.unparse(sampleData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "SampleClientData.csv";
        link.click();
    };

    // // Handle file upload and parse CSV
    // const handleFileUpload = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setLoading(true);
    //         Papa.parse(file, {
    //             header: true,
    //             skipEmptyLines: true,
    //             complete: (result) => {
    //                 const headers = Object.keys(sampleData[0]);
    //                 const uploadedHeaders = result.meta.fields;
    //                 const headersMatch = headers.every((header) =>
    //                     uploadedHeaders.includes(header)
    //                 );

    //                 if (headersMatch) {
    //                     const parsedData = result.data.map((row) => ({ ...row, id: uuidv4() }));
    //                     setData(parsedData);
    //                     setFilteredData(parsedData);
    //                     setSelectedRows([]);
    //                     setCurrentPage(1);
    //                 } else {
    //                     alert("Uploaded file headers do not match the required format.");
    //                 }
    //                 setLoading(false);
    //             },
    //         });
    //     }
    // };

    // Handle file upload and parse CSV
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoading(true);
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    const headers = Object.keys(sampleData[0]);
                    const uploadedHeaders = result.meta.fields;
                    const headersMatch = headers.every((header) =>
                        uploadedHeaders.includes(header)
                    );

                    if (headersMatch) {
                        const uniquePhoneNumbers = new Set();
                        const parsedData = result.data
                            .map((row) => ({ ...row, id: uuidv4() }))
                            .filter((row) => {
                                // Check for duplicate phone numbers
                                if (row.phoneNumber && !uniquePhoneNumbers.has(row.phoneNumber)) {
                                    uniquePhoneNumbers.add(row.phoneNumber);
                                    return true; // Include the row
                                }
                                return false; // Exclude the duplicate
                            });

                        setData(parsedData);
                        setFilteredData(parsedData);
                        setSelectedRows([]);
                        setCurrentPage(1);
                    } else {
                        alert("Uploaded file headers do not match the required format.");
                    }
                    setLoading(false);
                },
            });
        }
    };


    // Handle search functionality
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const filtered = data.filter((item) =>
            Object.values(item)
                .join(" ")
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    // Handle pagination
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle row selection
    const handleRowSelect = (row) => {
        if (selectedRows.some(selectedRow => selectedRow.id === row.id)) {
            // If row is already selected, remove it
            setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
        } else {
            // If row is not selected, add it
            setSelectedRows([...selectedRows, row]);
        }
    };

    const toggleSelectAllRows = () => {
        if (selectedRows.length === filteredData.length) {
            // If all rows are selected, deselect all
            setSelectedRows([]);
        } else {
            // If not all rows are selected, select all
            setSelectedRows(filteredData); // Assuming 'rows' is the array of all rows
        }
    };


    // Handle delete row
    const handleDeleteRow = (rowId) => {
        const updatedData = data.filter((row) => row.id !== rowId);
        setData(updatedData);
        setFilteredData(updatedData);
    };

    // Handle edit row
    const handleEditCell = (rowId, key, value) => {
        const updatedData = data.map((row) =>
            row.id === rowId ? { ...row, [key]: value } : row
        );
        setData(updatedData);
        setFilteredData(updatedData);
    };

    // Handle API submission
    const handleSubmit = async () => {
        if (selectedRows.length === 0) {
            alert("No rows selected for submission.");
            return;
        }

        // addClientInBulk
        const addClientInBulkResponse = await fetch(BASE_URL_API + "addClientInBulk",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    clientData: filteredData
                })
            });

        if (!addClientInBulkResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const addClientInBulkRespo = await addClientInBulkResponse.json();
            if (addClientInBulkRespo.status === "success") {
                // setCardDetails(addClientInBulkRespo.response);
                setData(addClientInBulkRespo?.response)
                setFilteredData(addClientInBulkRespo?.response)
            } else {
                if (addClientInBulkRespo.message === "tokenExpired") {
                    authCtx.logout();
                } else {
                    // setCardDetails([]);
                }
            }
            console.log(addClientInBulkRespo)
        }

        // Call your API with selectedRows
        console.log("Submitting data to API:", selectedRows);
        // alert("Data submitted successfully.");
    };


    if (activeStore === null) {
        return (
            <div>
                Please select a store first!
            </div>
        )
    }

    return (
        <div className={styles.uploadContainer}>
            <div>Upload Client Data in Bulk</div>
            <div className={styles.uploadActions}>
                <div className={styles.initSection}>
                    <button className={styles.downloadBtn} onClick={downloadSample}>
                        Download Sample Sheet
                    </button>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileUpload}
                        className={styles.fileUpload}
                    />
                </div>
                <div>
                    <button className={styles.submitBtn} onClick={handleSubmit}>
                        Submit Selected Rows
                    </button>
                </div>
            </div>
            {loading && <div className={styles.loader}>Uploading...</div>}
            <div className={styles.headerWrapper}>

                <div className={styles.searchBar}>
                    <input
                        type="text"
                        placeholder="Search data..."
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </div>
                <div className={styles.rowsPerPageSelector}>
                    <label>Rows per page: </label>
                    <select value={rowsPerPage} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
                        {[5, 10, 20, 50].map((num) => (
                            <option key={num} value={num}>
                                {num}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={styles.tableContainer}
            >
                <table>
                    <thead>
                        <tr>
                            <th onClick={toggleSelectAllRows}>
                                {selectedRows.length === filteredData.length ?
                                    <MdCheckBox size={22} />
                                    :
                                    <MdCheckBoxOutlineBlank size={22} />
                                }
                            </th>
                            {Object.keys(sampleData[0]).map((key) => (
                                key !== "id" && (
                                    <th key={key}>{key}</th>
                                )
                            ))}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((row) => (
                            <tr key={row.id}>
                                <td
                                    onClick={() => handleRowSelect(row)}
                                >
                                    {
                                        selectedRows.some(selectedRow => selectedRow.id === row.id)
                                            ?
                                            <MdCheckBox size={22} />
                                            :
                                            <MdCheckBoxOutlineBlank size={22} />
                                    }
                                </td>

                                {Object.entries(row).map(([key, value]) => (
                                    // Check if the key is not in the hiddenKeys array
                                    !hiddenKeys.includes(key) && (
                                        <td
                                            key={key}
                                            contentEditable
                                            suppressContentEditableWarning
                                            onBlur={(e) =>
                                                handleEditCell(row.id, key, e.target.innerText)
                                            }
                                        >
                                            {value}
                                        </td>
                                    )
                                ))}
                                <td>
                                    <FaTrashAlt
                                        className={styles.deleteIcon}
                                        onClick={() => handleDeleteRow(row.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </motion.div>
            <div className={styles.pagination}>
                {Array.from({ length: Math.ceil(filteredData.length / rowsPerPage) }).map(
                    (_, index) => (
                        <button
                            key={index}
                            className={currentPage === index + 1 ? styles.active : ""}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    )
                )}
            </div>

        </div>
    );
};

export default UploadClientDataInBulk;
