import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./StaffSearch.module.css";

const StaffSearch = ({ staffList, onSelectRecords }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredStaff, setFilteredStaff] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (searchTerm.trim() === "") {
            setFilteredStaff([]);
        } else {
            setFilteredStaff(
                staffList.filter((staff) =>
                    staff.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
    }, [searchTerm, staffList]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setFocusedIndex((prev) => Math.min(prev + 1, filteredStaff.length - 1));
        } else if (e.key === "ArrowUp") {
            setFocusedIndex((prev) => Math.max(prev - 1, 0));
        } else if (e.key === "Enter" && focusedIndex >= 0) {
            onSelectRecords(filteredStaff[focusedIndex]);
            setFilteredStaff([]); // Close suggestion list
            setSearchTerm(""); // Clear search term
        }
    };

    const handleSelect = (staff) => {
        onSelectRecords(staff);
        setFilteredStaff([]); // Close suggestion list
        setSearchTerm(""); // Clear search term
        setIsFocused(false); // Blur the input
    };

    return (
        <div className={styles.staffSearch}>
            <input
                type="text"
                placeholder="Search staff..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setTimeout(() => setIsFocused(false), 200)}
                className={styles.searchInput}
            />

            <AnimatePresence>
                {isFocused && filteredStaff.length > 0 && (
                    <motion.ul
                        className={styles.suggestionList}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                    >
                        {filteredStaff?.map((staff, index) => (
                            <motion.li
                                key={index}
                                className={`${styles.suggestionItem} ${index === focusedIndex ? styles.focused : ""
                                    }`}
                                onClick={() => handleSelect(staff)}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <div className={styles.staffDetails}>
                                    <span className={styles.staffName}>{staff.name}</span>
                                    {staff.role && <span className={styles.staffRole}>{staff.role}</span>}
                                </div>
                            </motion.li>
                        ))}
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    );
};

export default StaffSearch;
