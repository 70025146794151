import React from "react";
import styles from "./ElegantBlissTheme.module.css";

const ElegantBlissTheme = ({ invoiceDataMaster, themeData }) => {
    const { meta } = themeData;
    const { clientData, productData, totalAmount, tncData, customFields, invoiceDate, dueDate } =
        invoiceDataMaster;

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: meta.backgroundColor,
                color: meta.textColor,
                fontFamily: meta.fontFamily,
            }}
            // id="invoicePreview"
        >
            {/* Header */}
            <header className={styles.header}>
                <div className={styles.logoSection}>
                    <h1 style={{ color: meta.primaryColor }}>Elegant Embrace</h1>
                    <p>345 W Main, Los Angeles, CA 14151</p>
                    <p>P: 915-555-0195 | F: 915-555-0105</p>
                    <p>elegantembrace@example.com</p>
                </div>
                <div className={styles.invoiceSection}>
                    <h2 style={{ color: meta.primaryTextColor }}>INVOICE</h2>
                    <p>Invoice #: {invoiceDataMaster.sessionIdentifier.slice(-6)}</p>
                    <p>Invoice Date: {invoiceDate}</p>
                    <p>Due Date: {dueDate}</p>
                </div>
            </header>

            {/* Client Information */}
            <section className={styles.clientInfo}>
                <h3 style={{ color: meta.secondaryTextColor }}>Bill To:</h3>
                <p>{`${clientData.salutation} ${clientData.client_name}`}</p>
                <p>{clientData.client_address}</p>
                <p>Phone: {clientData.mobile}</p>
                <p>Email: {clientData.client_email}</p>
            </section>

            {/* Product Table */}
            <table className={styles.productTable}>
                <thead style={{ backgroundColor: meta.primaryColor, color: "#fff" }}>
                    <tr>
                        <th>#</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Discount</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {productData.map((product, index) => (
                        <tr key={index}>
                            {/* <td>{product.productIdentifier.slice(0, 5)}</td> */}
                            <td>{index+1}.</td>
                            <td>{product.productTitle}</td>
                            <td>{product.qty}</td>
                            <td>₹{product.price}</td>
                            <td>{product.discount}%</td>
                            <td>₹{product.total}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Summary */}
            <section className={styles.summary}>
                <div>
                    <p>Subtotal:</p>
                    <p>Tax Rate:</p>
                    <p>Custom Deduction:</p>
                    <p style={{ fontWeight: "bold" }}>Total:</p>
                </div>
                <div>
                    <p>₹{totalAmount}</p>
                    <p>18%</p>
                    <p>-₹{customFields[0]?.value}</p>
                    <p style={{ fontWeight: "bold" }}>₹{totalAmount - customFields[0]?.value}</p>
                </div>
            </section>

            {/* Terms & Conditions */}
            <footer className={styles.footer}>
                <h4 style={{ color: meta.primaryColor }}>Terms & Conditions</h4>
                <ul>
                    {tncData.map((tnc, index) => (
                        <li key={index}>{tnc.detail}</li>
                    ))}
                </ul>
                <p style={{ fontSize: meta.tertiaryFontSize, marginTop: "10px" }}>
                    Please make all checks payable to Elegant Embrace. Overdue accounts are subject to a
                    service charge of 1.5% per month.
                </p>
            </footer>
        </div>
    );
};

export default ElegantBlissTheme;
