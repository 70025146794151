import React, { useState, useEffect, useContext } from 'react';
import styles from './ComCreateCampaigns.module.css';
import { FaCheckCircle, FaRegCircle, FaSpinner } from 'react-icons/fa'; // React Icons
import CampaignDetails from '../../Components/Communications/Campaigns/CampaignDetails';
import MessageTemplates from '../../Components/Communications/Campaigns/MessageTemplates';
import CampaignAudience from '../../Components/Communications/Campaigns/CampaignAudience';
import CampaignScheduler from '../../Components/Communications/Campaigns/CampaignScheduler';
import CampaignLaunch from '../../Components/Communications/Campaigns/CampaignLaunch';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';

const ComCreateCampaigns = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [currentStep, setCurrentStep] = useState(1);
    const [campaignData, setCampaignData] = useState({
        name: '',
        messageTemplate: '',
        audience: '',
        deliveryMessageSource: '',
        schedule: '',
        status: 'draft',
    });
    const [loading, setLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const progress = (currentStep / 5) * 100;

    useEffect(() => {
        // Mock loading state to simulate template loading
        if (currentStep === 2) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 2000); // Simulate loading delay
        }
    }, [currentStep]);

    const nextStep = () => {
        if (currentStep < 5) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const saveCampaign = () => {
        // Mock saving functionality
        setIsSaved(true);
        console.log('Campaign Saved:', campaignData);
        setTimeout(() => setIsSaved(false), 3000); // Reset after a few seconds
    };

    const handleSidebarClick = (step) => {
        setCurrentStep(step);
    };


    if(!activeStore) {
        return (
            <div className={styles.container} >
                Please select a audience!
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {/* Left Sidebar */}
            <div className={styles.sidebar}>
                <div
                    className={`${styles.sidebarItem} ${currentStep === 1 ? styles.active : ''}`}
                    onClick={() => handleSidebarClick(1)}
                >
                    <FaRegCircle /> Campaign
                </div>
                <div
                    className={`${styles.sidebarItem} ${currentStep === 2 ? styles.active : ''}`}
                    onClick={() => handleSidebarClick(2)}
                >
                    <FaRegCircle /> Message Template
                    {currentStep === 2 && loading && (
                        <span className={styles.loadingIcon}>
                            <FaSpinner className={styles.spinner} />
                        </span>
                    )}
                </div>
                <div
                    className={`${styles.sidebarItem} ${currentStep === 3 ? styles.active : ''}`}
                    onClick={() => handleSidebarClick(3)}
                >
                    <FaRegCircle /> Audience
                </div>
                <div
                    className={`${styles.sidebarItem} ${currentStep === 4 ? styles.active : ''}`}
                    onClick={() => handleSidebarClick(4)}
                >
                    <FaRegCircle /> Scheduler
                </div>
                <div
                    className={`${styles.sidebarItem} ${currentStep === 5 ? styles.active : ''}`}
                    onClick={() => handleSidebarClick(5)}
                >
                    <FaRegCircle /> Launch
                </div>
            </div>

            {/* Main Content */}
            <div className={styles.content}>
                {currentStep === 1 && <CampaignDetails data={campaignData} setData={setCampaignData} />}
                {currentStep === 2 && <MessageTemplates data={campaignData} setData={setCampaignData} />}
                {currentStep === 3 && <CampaignAudience data={campaignData} setData={setCampaignData} />}
                {currentStep === 4 && <CampaignScheduler data={campaignData} setData={setCampaignData} />}
                {currentStep === 5 && <CampaignLaunch data={campaignData} setData={setCampaignData} />}
            </div>

            {/* Footer and Progress */}
            <div className={styles.progressContainer}>
                <div className={styles.progressBarWrapper}>
                    <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
                </div>
            </div>

            <div className={styles.footer}>
                <button
                    onClick={prevStep}
                    disabled={currentStep === 1}
                    className={`${styles.footerBtn} ${currentStep === 1 ? styles.disabled : ''}`}
                >
                    Prev
                </button>
                <button
                    onClick={nextStep}
                    className={`${styles.footerBtn} ${currentStep === 5 ? styles.disabled : ''}`}
                >
                    Next
                </button>
                <button
                    onClick={saveCampaign}
                    className={`${styles.footerSaveBtn} ${isSaved ? styles.saved : ''}`}
                >
                    {isSaved ? 'Saved!' : 'Save Progress'}
                </button>
            </div>
        </div>
    );
};

export default ComCreateCampaigns;
