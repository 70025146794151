import React, { useContext, useEffect, useState } from 'react';
import { Pie, Line } from 'react-chartjs-2';
import { motion } from 'framer-motion';
import { FaStar, FaSmile, FaFrown } from 'react-icons/fa';
import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import ReviewsHolder from '../../Components/Feedback/ReviewsHolder';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './FeedbackHome.module.css';

// Register required elements for Chart.js
ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend
);

const FeedbackHome = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null;
        } catch {
            return null;
        }
    })();

    const [isLoading, setIsLoading] = useState(true);
    const [feedbackData, setFeedbackData] = useState([]);
    const [avgRating, setAvgRating] = useState(0);
    const [sentimentSummary, setSentimentSummary] = useState({ positive: 0, negative: 0 });

    const getAllFeedbacks = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(BASE_URL_API + "getAllFeedbacks", {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                }),
            });
            if (!response.ok) throw new Error("Failed to fetch feedback data");

            const data = await response.json();
            if (data.status === 'success') {
                setFeedbackData(data.response);
                calculateInsights(data.response);
            } else if (data.message === 'tokenExpired') {
                authCtx.logout();
            }
        } catch (error) {
            console.error("Error fetching feedback data:", error);
        }
        setIsLoading(false);
    };

    const calculateInsights = (data) => {
        const totalRatings = data.length;
        const sumRatings = data.reduce((sum, item) => sum + parseInt(item.selectedStar, 10), 0);
        setAvgRating((sumRatings / totalRatings).toFixed(1));

        let positiveCount = 0, negativeCount = 0;
        data.forEach(item => {
            if (parseInt(item.selectedStar, 10) >= 4) positiveCount++;
            else if (parseInt(item.selectedStar, 10) <= 2) negativeCount++;
        });
        setSentimentSummary({ positive: positiveCount, negative: negativeCount });
    };

    useEffect(() => {
        if (activeStore !== null) {
            getAllFeedbacks();
        }
    }, [activeStore?.storeIdentifier]);

    if (!activeStore) {
        return <div className={styles.errorMessage}>Please select a store first!</div>;
    }

    const pieData = {
        labels: ['1 Star', '2 Stars', '3 Stars', '4 Stars', '5 Stars'],
        datasets: [{
            label: 'Rating Distribution',
            data: [
                feedbackData.filter(f => f.selectedStar === "1").length,
                feedbackData.filter(f => f.selectedStar === "2").length,
                feedbackData.filter(f => f.selectedStar === "3").length,
                feedbackData.filter(f => f.selectedStar === "4").length,
                feedbackData.filter(f => f.selectedStar === "5").length
            ],
            backgroundColor: ['#FF0000', '#FFA500', '#FFFF00', '#008000', '#0000FF'],
        }]
    };

    const lineData = {
        labels: feedbackData.map(f => new Date(f.ratedOn).toLocaleDateString()),
        datasets: [{
            label: 'Feedback Over Time',
            data: feedbackData.map(f => parseInt(f.selectedStar, 10)),
            fill: false,
            borderColor: '#42A5F5',
            tension: 0.1
        }]
    };

    return (
        <motion.div className={styles.mainWrapper} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            <div className={styles.headerWrapper}><div>Feedback Dashboard</div></div>

            <div className={styles.bodyWrapper}>
                <div className={styles.leftWrapper}>
                    <div className={styles.graphsWrapper}>
                        <div className={styles.avgRatingWrapper}>
                            <h2>Average Rating</h2>
                            <div className={styles.avgRating}><FaStar color="#FFD700" /> {avgRating}</div>
                            <p>Based on {feedbackData.length} reviews</p>
                        </div>

                        <div className={styles.graphWrapper}>
                            <Pie data={pieData} />
                        </div>
                    </div>

                    <div className={styles.graphsWrapper}>
                        <div className={styles.trendWrapper}>
                            <div>Feedback Trends</div>
                            <Line height={120} data={lineData} />
                        </div>
                    </div>

                    <motion.div className={styles.sentimentWrapper} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.6 }}>
                        <h3 className={styles.sentimentTitle}>Sentiment Summary</h3>
                        <motion.div className={styles.sentimentItem} whileHover={{ scale: 1.1 }}>
                            <FaSmile color="#28a745" size={24} />
                            <span className={styles.sentimentText}>Positive: {sentimentSummary.positive}</span>
                        </motion.div>
                        <motion.div className={styles.sentimentItem} whileHover={{ scale: 1.1 }}>
                            <FaFrown color="#dc3545" size={24} />
                            <span className={styles.sentimentText}>Negative: {sentimentSummary.negative}</span>
                        </motion.div>
                    </motion.div>
                </div>

                <div className={styles.rightWrapper}>
                    <ReviewsHolder isLoading={isLoading} feedbackData={feedbackData} />
                </div>
            </div>
        </motion.div>
    );
};

export default FeedbackHome;
