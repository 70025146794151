import React, { useState, useEffect } from 'react';
import { FaPen, FaFileAlt } from 'react-icons/fa';
import styles from './CampaignDetails.module.css';
import { motion } from 'framer-motion';

const CampaignDetails = ({ data, setData }) => {
    const [title, setTitle] = useState(data.name);
    const [description, setDescription] = useState(data.description);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        setData({ ...data, name: e.target.value });
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        setData({ ...data, description: e.target.value });
    };

    useEffect(() => {
        console.log("Campaign Data: ", data); // To check the data whenever it changes
    }, [data]);

    return (
        <div className={styles.container}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className={styles.header}
            >
                Campaign Details
            </motion.div>

            <div className={styles.inputGroup}>
                <label htmlFor="title">
                    <FaPen className={styles.icon} />
                    <span>Title</span>
                </label>
                <motion.input
                    id="title"
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Enter campaign title"
                    className={styles.input}
                />
            </div>

            <div className={styles.inputGroup}>
                <label htmlFor="description">
                    <FaFileAlt className={styles.icon} />
                    <span>Description</span>
                </label>
                <motion.textarea
                    id="description"
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Enter campaign description"
                    className={styles.textarea}
                />
            </div>
        </div>
    );
};

export default CampaignDetails;
