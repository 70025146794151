import React, { useState, useEffect } from "react";
import styles from "./ProductSearch.module.css";
import SearchableDropdown from "./SearchableDropdown";


const ProductSearch = ({ allRecords, productTitle, onSelectRecords }) => {
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        setFilteredData(allRecords);
    }, [allRecords]);

    return (
        <div className={styles.panelHeader}>
            <SearchableDropdown
                placeholder="Search product by name"
                data={filteredData}
                filterKey="serviceName"
                onItemSelect={onSelectRecords}
                productTitle={productTitle}
            />
        </div>
    );
};

export default ProductSearch;
