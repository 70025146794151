import React, { useContext, useEffect, useState } from "react";
import { BiFemale, BiCrown } from "react-icons/bi";
import { BsGenderMale } from "react-icons/bs";
import { FaMedal } from "react-icons/fa";
import { motion } from "framer-motion";
import { BASE_URL_API } from "../../References/URLs";
import ActiveStoreContext from "../../store/ActiveStoreContext";
import AuthContext from "../../store/auth-context";
import styles from "./StarPerformers.module.css";

const StarPerformers = ({ dates }) => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return asCtx?.activeStore?.trim()
                ? JSON.parse(asCtx.activeStore)
                : null;
        } catch {
            return null;
        }
    })();
    const [performanceData, setPerformanceData] = useState([]);

    const getStaffPerformanceSummary = async () => {
        console.log('getting staff summary....')
        const response = await fetch(BASE_URL_API + "getStaffPerformanceSummary", {
            method: "POST",
            body: JSON.stringify({
                token: authCtx.token,
                storeIdentifier: activeStore?.storeIdentifier,
                startDate: dates.startDate,
                endDate: dates.endDate,
            }),
        });

        const data = await response.json();
        if (data.status === "success") {
            setPerformanceData(data.response || []);
        } else if (data.message === "tokenExpired") {
            authCtx.logout();
        }
    };

    useEffect(() => {
        getStaffPerformanceSummary();
    }, [dates]);

    const getGenderIcon = (gender) =>
        gender === "Male" ? (
            <BsGenderMale className={styles.genderIcon} />
        ) : (
            <BiFemale className={styles.genderIcon} />
        );

    const getRankBadge = (rank) => {
        const colors = ["gold", "silver", "bronze"];
        return (
            <FaMedal
                className={styles.medalIcon}
                style={{ color: colors[rank - 1] || "#888" }}
            />
        );
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>🌟 Star Performers 🌟</div>

            {/* Top Performer */}
            {performanceData[0] && (
                <motion.div
                    className={styles.topPerformer}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <div className={styles.crown}>
                        <BiCrown />
                    </div>
                    {getGenderIcon(performanceData[0].servedByGender)}
                    <h2>{performanceData[0].servedByName}</h2>
                    <p className={styles.sales}>₹{performanceData[0].totalSales.toFixed(2)}</p>
                    <p className={styles.bookings}>{performanceData[0].totalBookings} Bookings</p>
                </motion.div>
            )}

            {/* Runner-Ups */}
            <div className={styles.runnerUps}>
                {performanceData.slice(1, 3).map((item, index) => (
                    <motion.div
                        key={index}
                        className={styles.runnerCard}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.2 }}
                    >
                        {getRankBadge(index + 2)}
                        {getGenderIcon(item.servedByGender)}
                        <h3>{item.servedByName}</h3>
                        <p>₹{item.totalSales.toFixed(2)}</p>
                        <p>{item.totalBookings} Bookings</p>
                    </motion.div>
                ))}
            </div>

            {/* Leaderboard */}
            <div className={styles.leaderboard}>
                {performanceData.slice(3).map((item, index) => (
                    <div key={index} className={styles.leaderboardRow}>
                        <span className={styles.rank}>#{index + 4}</span>
                        {getGenderIcon(item.servedByGender)}
                        <span className={styles.name}>{item.servedByName}</span>
                        <span className={styles.sales}>₹{item.totalSales.toFixed(2)}</span>
                        <span className={styles.bookings}>{item.totalBookings} Bookings</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StarPerformers;
