import React, { useContext, useEffect, useState } from 'react'
import styles from './UpdateService.module.css'
import { useNavigate } from 'react-router-dom'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import { FaCheckSquare, FaRegCheckSquare, FaSave, FaSquare, FaToggleOff, FaToggleOn } from 'react-icons/fa'
import { toast, ToastContainer } from 'react-toastify'

const UpdateService = (props) => {
    const { serviceIdentifier, isOpen, onClose } = props
    const nav = useNavigate()
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [serviceDetails, setServiceDetails] = useState(null)

    window.onclick = event => event.target.id === "closeUpdateService" ? nav(`/manage/price-list`) : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            nav(`/manage/price-list`)
        }
    });
    const [isManagingTaxes, setIsManagingTaxes] = useState(false)
    const [isManagingStock, setIsManagingStock] = useState(false)
    const [gstRates, setGstRates] = useState([0, 0.1, 0.25, 1, 1.5, 3, 5, 6, 7.5, 12, 18, 28])

    // Value options (e.g., 1, 2, 3, 4)
    const valueOptions = Array.from({ length: 31 }, (_, i) => i + 1); // Values from 1 to 31

    // Unit options (e.g., Days, Weeks, Months)
    const unitOptions = [
        { label: 'Day(s)', value: 'days' },
        { label: 'Week(s)', value: 'weeks' },
        { label: 'Month(s)', value: 'months' },
        { label: 'Year(s)', value: 'years' },
    ];

    const handleValueChange = (event) => {
        setServiceDetails((prevDetails) => ({
            ...prevDetails,
            frequencyValue: event.target.value,
        }));
    };

    const handleUnitChange = (event) => {
        setServiceDetails((prevDetails) => ({
            ...prevDetails,
            frequencyUnit: event.target.value,
        }));
    };

    const discountChangeHandler = (k) => {
        if (k >= 0 && k <= 100) {  // Correct condition
            setServiceDetails((prevDetails) => ({
                ...prevDetails,
                offerPercentage: k
            }));
        } else {
            alert('Cannot enter discount value more than 100% or less than 0%');
        }
    };


    useEffect(() => {
        setServiceDetails((prevDetails) => ({
            ...prevDetails,
            discountedPrice: parseFloat(parseFloat(serviceDetails?.basePrice) * parseFloat((100 - parseFloat(serviceDetails?.offerPercentage || 0)) / 100)).toFixed(2)
        }));
    }, [serviceDetails?.offerPercentage, serviceDetails?.basePrice])



    const getEachServiceDetails = async () => {
        const getEachServiceDetailsResponse = await fetch(BASE_URL_API + "getEachServiceDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    serviceIdentifier: serviceIdentifier
                })
            });

        if (!getEachServiceDetailsResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getEachServiceDetailsRespo = await getEachServiceDetailsResponse.json()
            if (getEachServiceDetailsRespo.status === "success") {
                setServiceDetails(getEachServiceDetailsRespo?.response)
            } else {
                if (getEachServiceDetailsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setServiceDetails(null)
                }
            }
            console.log(getEachServiceDetailsRespo?.response)
        }
    }

    useEffect(() => {
        getEachServiceDetails()
    }, [serviceIdentifier])

    const updateService = async () => {
        console.log(serviceDetails)
        const updateServiceResponse = await fetch(BASE_URL_API+"updateService",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                serviceIdentifier : serviceDetails?.identifier,
                basePrice : serviceDetails?.basePrice,
                description : serviceDetails?.description,
                frequencyUnit : serviceDetails?.frequencyUnit,
                frequencyValue : serviceDetails?.frequencyValue,
                gstRate : serviceDetails?.gstRate,
                gstType : serviceDetails?.gstType,
                isManagingStock : serviceDetails?.isManagingStock,
                isNotITC : serviceDetails?.isNotITC,
                numOfSittingsRequired : serviceDetails?.numOfSittingsRequired,
                offerPercentage : serviceDetails?.offerPercentage,
                reorderValue : serviceDetails?.reorderValue,
                serviceDuration : serviceDetails?.serviceDuration,
                serviceDurationUnit : serviceDetails?.serviceDurationUnit,
                serviceName : serviceDetails?.serviceName,
                stockQuantity : serviceDetails?.stockQuantity,
                unitOfMeasure : serviceDetails?.unitOfMeasure
            })
        });

        if(!updateServiceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateServiceRespo = await updateServiceResponse.json()
            if(updateServiceRespo.status==="success") {
                toast(`Successfully updated!`)
            } else {
                if(updateServiceRespo.message==="tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateServiceRespo)
        }
    }


    return (
        <div className={styles.mainWrapper} >
            <div className={styles.mainContainer} id="closeUpdateService">
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <button onClick={() => updateService()}>
                            <FaSave /> Save
                        </button>
                    </div>
                    <div className={styles.mainBody}>
                        <div className={styles.eachFieldWrapper}>
                            <div>Item Name</div>
                            <div className={styles.efwInputWrapper}>
                                <input placeholder=" Name..." value={serviceDetails?.serviceName}
                                    onChange={(e) => {
                                        setServiceDetails((prevDetails) => ({
                                            ...prevDetails,
                                            serviceName: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        {/* <div className={styles.eachFieldWrapper}>
                            <div className={styles.efwSelectWrapper}>
                                <select>
                                    <option>Category</option>
                                    <option>Category</option>
                                </select>
                            </div>
                        </div> */}
                        <div className={styles.eachFieldWrapperPrice}>
                            <div className={styles.efwInputWrapper}>
                                <input placeholder="Price" value={serviceDetails?.basePrice}
                                    onChange={(e) => {
                                        setServiceDetails((prevDetails) => ({
                                            ...prevDetails,
                                            basePrice: e.target.value,
                                        }));
                                    }}
                                    value={serviceDetails?.basePrice}
                                    type={"number"}
                                />
                            </div>
                            <div className={styles.efwInputWrapper}>
                                <input placeholder="Discount"
                                    onChange={(e) => discountChangeHandler(e.target.value)}
                                    value={serviceDetails?.offerPercentage}
                                    type={"number"}
                                />
                            </div>
                            <div className={styles.efwInputWrapper}>
                                <input placeholder="Discounted Price"
                                    readOnly={true}
                                    value={serviceDetails?.discountedPrice}
                                />
                            </div>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <textarea placeholder="Product description..." rows={5}
                                value={serviceDetails?.description}
                                onChange={(e) => {
                                    setServiceDetails((prevDetails) => ({
                                        ...prevDetails,
                                        description: e.target.value,
                                    }))
                                }}
                            />
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <div>Duration</div>
                            <div className={styles.durationWrapper}>
                                <div>
                                    <select
                                        onChange={(e) => {
                                            setServiceDetails((prevDetails) => ({
                                                ...prevDetails,
                                                serviceDuration: e.target.value,
                                            }));
                                        }}
                                        value={serviceDetails?.serviceDuration ?? "Any"}
                                    >
                                        <option value="Any">Select Duration</option>
                                        {Array.from({ length: 101 }, (_, index) => (
                                            <option key={index} value={index}>
                                                {index}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <select
                                        onChange={(e) => {
                                            setServiceDetails((prevDetails) => ({
                                                ...prevDetails,
                                                serviceDurationUnit: e.target.value,
                                            }));
                                        }}
                                        value={serviceDetails?.serviceDurationUnit ?? "Any"}
                                    >
                                        <option value="Any">Select Duration Unit</option>
                                        <option value="Seconds">Seconds</option>
                                        <option value="Minutes">Minutes</option>
                                        <option value="Hours">Hours</option>
                                        <option value="Days">Days</option>
                                        <option value="Weeks">Weeks</option>
                                        <option value="Months">Months</option>
                                        <option value="Years">Years</option>
                                    </select>

                                </div>
                            </div>

                            <div className={styles.fnsWrapper}>
                                <div className={styles.fnsEachBig}>
                                    <div className={styles.eachLabel}>
                                        Frequency (Repeat After Every)
                                    </div>
                                    <div className={styles.frequencyContainer}>
                                        <select
                                            onChange={handleValueChange}
                                            value={serviceDetails?.frequencyValue ?? ""}
                                        >
                                            <option value="" disabled>Select Value</option>
                                            {valueOptions.map((val) => (
                                                <option key={val} value={val}>
                                                    {val}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            value={serviceDetails?.frequencyUnit}
                                            onChange={handleUnitChange}
                                        >
                                            <option value="" disabled>Select Unit</option>
                                            {unitOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className={styles.fnsEach}>
                                    <div className={styles.eachLabel}>Sittings Recommended</div>
                                    <div className={styles.frequencyContainer}>
                                        <select
                                            onChange={(e) => {
                                                setServiceDetails((prevDetails) => ({
                                                    ...prevDetails,
                                                    numOfSittingsRequired: e.target.value,
                                                }))
                                            }}
                                            value={serviceDetails?.numOfSittingsRequired ?? "Any"}  // Handle null case
                                        >
                                            <option value="Any" disabled>Any</option>
                                            {Array.from({ length: 101 }, (_, index) => (
                                                <option key={index} value={index}>
                                                    {index}
                                                </option>
                                            ))}
                                            <option value={99999999}>Unlimited</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={styles.sectionWrapper}>
                            <div className={styles.sectionHeader} >
                                <div className={styles.sectionTitle}>
                                    Manage Taxes
                                </div>
                                <div className={styles.sectionIcon} onClick={() => setIsManagingTaxes(!isManagingTaxes)} >
                                    {isManagingTaxes === false ? <FaToggleOff size={26} /> : <FaToggleOn size={26} />}
                                </div>
                            </div>
                            {isManagingTaxes === true &&
                                <div className={styles.mtContentWrapper}>
                                    <div className={styles.mtContentContainer}>
                                        <div className={styles.etw}>
                                            <div className={styles.etwCaption}>
                                                Product Type
                                            </div>
                                            <div className={styles.etwValue}>
                                                <select onChange={(e) => {
                                                    setServiceDetails((prevDetails) => ({
                                                        ...prevDetails,
                                                        gstType: e.target.value,
                                                    }));
                                                }} >
                                                    <option value={`Taxable`} >Taxable</option>
                                                    <option value={`Nil Rated`} >Nil Rated</option>
                                                    <option value={`Exempted`} >Exempted</option>
                                                    <option value={`Non-GST`} >Non-GST</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className={styles.etw}>
                                            <div className={styles.etwCaption}>
                                                GST Rate
                                            </div>
                                            <div className={styles.etwValue}>
                                                <select onChange={(e) => {
                                                    setServiceDetails((prevDetails) => ({
                                                        ...prevDetails,
                                                        gstRate: e.target.value,
                                                    }));
                                                }} >
                                                    {gstRates?.map((item, index) => (
                                                        <option key={index}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className={styles.etw}>
                                            <div className={styles.etwCaption}>
                                                No-ITC
                                            </div>
                                            <div className={styles.etwValue}
                                                onClick={() => {
                                                    setServiceDetails((prevDetails) => ({
                                                        ...prevDetails,
                                                        isNotITC: !serviceDetails?.isNotITC,
                                                    }));
                                                }}
                                            >
                                                {serviceDetails?.isNotITC === true ? <FaCheckSquare /> : <FaSquare />}
                                                <p>Is Product ineligible for Input Credit?</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={styles.sectionWrapper}>
                            <div className={styles.sectionHeader} >
                                <div className={styles.sectionTitle}>
                                    Manage Stock
                                </div>
                                <div className={styles.sectionIcon} onClick={() => {
                                    setServiceDetails((prevDetails) => ({
                                        ...prevDetails,
                                        isManagingStock: !serviceDetails?.isManagingStock
                                    }));
                                }}
                                >
                                    {serviceDetails?.isManagingStock === false ? <FaToggleOff size={26} /> : <FaToggleOn size={26} />}
                                </div>
                            </div>
                            {serviceDetails?.isManagingStock === true &&
                                <div className={styles.sectionFieldWrapper}>
                                    <div className={styles.etw}>
                                        <div className={styles.etwCaption}>
                                            Stock quantity
                                        </div>
                                        <div className={styles.etwValue}>
                                            <input onChange={(e) => {
                                                setServiceDetails((prevDetails) => ({
                                                    ...prevDetails,
                                                    stockQuantity: e.target.value,
                                                }));
                                            }}
                                                placeholder="Enter stock quantity"
                                                type="number"
                                                value={serviceDetails?.stockQuantity}
                                            />

                                        </div>
                                    </div>
                                    <div className={styles.etw}>
                                        <div className={styles.etwCaption}>
                                            Low stock alert / Re-order threshold
                                        </div>
                                        <div className={styles.etwValue}>
                                            <input onChange={(e) => {
                                                setServiceDetails((prevDetails) => ({
                                                    ...prevDetails,
                                                    reorderValue: e.target.value,
                                                }));
                                            }}
                                                placeholder="Enter stock quantity"
                                                type="number"
                                                value={serviceDetails?.reorderValue}
                                            />

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.sectionWrapperThatsAll}>
                            <div className={styles.thatsAll}>
                                That's all!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UpdateService